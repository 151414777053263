import React, { useRef } from "react";

import { RootState } from "../store/RootReducer";
import { connect, useDispatch } from "react-redux";
import { useAuth } from "../context/AuthContext";
import { setLoginModal } from "../store/modals/login/Actions";
import { menuController } from "@ionic/core";
import { useParams } from "react-router";
import { IonIcon, IonRouterLink } from "@ionic/react";
import {
    calendarOutline,
    cartOutline,
    chevronDownOutline,
    personAddOutline,
    personCircleOutline,
} from "ionicons/icons";
import moment from "moment";

import { ModelCartItem } from "../models/FoodModel";

import { setRegistrationModal } from "../store/modals/registration/Actions";
import { setProfileModal } from "../store/modals/profile/Actions";

import Dropdown, {
    DropdownTrigger,
    DropdownContent,
} from "react-simple-dropdown";
import { sendBarionPixel } from "../helpers/barion/PixelHelper";

import logo from "../assets/images/cityfood-logo.png";
import vitalLogo from "../assets/images/vital-logo.png";
import paleoLogo from "../assets/images/paleo-logo.png";
import lastMinuteLogo from "../assets/images/last-minute-logo.png";

import "./Header.scss";

interface Props {
    cartItems: Array<ModelCartItem>;
    type?: string;
}

const Header: React.FC<Props> = ({ cartItems, type }) => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();
    const { year, week } = useParams<any>();

    const onLoginBtn = () => {
        dispatch(setLoginModal(true));
    };

    const onCartBtn = () => {
        menuController.isOpen("cartDetailsMenu").then((result: boolean) => {
            if (!result) {
                menuController.getMenus().then((menus: any) => {
                    menus.map((menu: any, _: number) => {
                        menuController.enable(false, menu.menuId);
                    });
                    menuController.enable(true, "cartDetailsMenu");
                    menuController.open("cartDetailsMenu");
                });
            } else {
                menuController.close("cartDetailsMenu");
            }
        });
    };

    const openRegistrationModal = () => {
        dispatch(setRegistrationModal(true));
    };

    const handleOpenProfile = () => {
        dispatch(setProfileModal(true, "data"));

        if (authInfo.userInfo) {
            sendBarionPixel("setUserProperties", {
                userId: authInfo.userInfo.id.toString(),
            });
        }
    };

    const handleOpenOrders = () => {
        dispatch(setProfileModal(true, "orders"));
    };

    const dropDownRef = useRef<any>();

    return (
        <div id="Header">
            <div className="HeaderTop">
                <div className="header-left">
                    <IonRouterLink
                        routerDirection="root"
                        routerLink="/"
                        id="cityLogo"
                    >
                        <img
                            src={logo}
                            alt="Logo"
                            className={type === undefined ? "active" : ""}
                        />
                    </IonRouterLink>
                    <IonRouterLink
                        routerLink={`/vitalkonyha-rendeles/${
                            year ?? moment(moment()).year()
                        }/${week ?? moment(moment()).week()}`}
                        id="vitalkonyhaLogo"
                    >
                        <img
                            src={vitalLogo}
                            alt="Vital"
                            className={
                                type && type === "vitalkonyha" ? "active" : ""
                            }
                        />
                    </IonRouterLink>
                    <IonRouterLink
                        routerLink={`/paleo-rendeles/${
                            year ?? moment(moment()).year()
                        }/${week ?? moment(moment()).week()}`}
                        id="paleoLogo"
                    >
                        <img
                            src={paleoLogo}
                            alt="Paleo"
                            className={type && type === "paleo" ? "active" : ""}
                        />
                    </IonRouterLink>
                    <IonRouterLink
                        routerLink="/last-minute/"
                        id="lastMinuteLogo"
                    >
                        <img
                            src={lastMinuteLogo}
                            alt="Last Minute"
                            className="active"
                        />
                    </IonRouterLink>

                    <div id="mobileLogo">
                        <Dropdown ref={dropDownRef}>
                            <DropdownTrigger className="filter-button">
                                <div>
                                    {type && type === "paleo" && (
                                        <img
                                            src={paleoLogo}
                                            alt="Paleo"
                                            className="active"
                                        />
                                    )}
                                    {type && type === "vitalkonyha" && (
                                        <img
                                            src={vitalLogo}
                                            alt="Vital"
                                            className="active"
                                        />
                                    )}
                                    {type === undefined && (
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            className="active"
                                        />
                                    )}
                                    <IonIcon icon={chevronDownOutline} />
                                </div>
                            </DropdownTrigger>
                            <DropdownContent>
                                <div className="logos">
                                    <IonRouterLink
                                        routerDirection="root"
                                        routerLink="/"
                                        onClick={() =>
                                            dropDownRef.current.hide()
                                        }
                                    >
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            className="active"
                                        />
                                    </IonRouterLink>
                                    <IonRouterLink
                                        routerDirection="root"
                                        routerLink={`/vitalkonyha-rendeles/${
                                            year ?? moment(moment()).year()
                                        }/${week ?? moment(moment()).week()}`}
                                        onClick={() =>
                                            dropDownRef.current.hide()
                                        }
                                    >
                                        <img
                                            src={vitalLogo}
                                            alt="Vital"
                                            className="active"
                                        />
                                    </IonRouterLink>
                                    <IonRouterLink
                                        routerDirection="root"
                                        routerLink={`/paleo-rendeles/${
                                            year ?? moment(moment()).year()
                                        }/${week ?? moment(moment()).week()}`}
                                        onClick={() =>
                                            dropDownRef.current.hide()
                                        }
                                    >
                                        <img
                                            src={paleoLogo}
                                            alt="Paleo"
                                            className="active"
                                        />
                                    </IonRouterLink>
                                </div>
                            </DropdownContent>
                        </Dropdown>
                    </div>
                </div>
                <div className="header-right">
                    {authInfo.loggedIn &&
                    authInfo.userInfo &&
                    authInfo.userInfo.name ? (
                        <>
                            <button
                                className="profile-button"
                                onClick={handleOpenOrders}
                                id="headerOrdersButton"
                            >
                                <IonIcon icon={calendarOutline} />
                                <span>Rendeléseim</span>
                            </button>
                            <button
                                className="profile-button"
                                onClick={handleOpenProfile}
                            >
                                <IonIcon icon={personCircleOutline} />
                                <span>{authInfo.userInfo.name}</span>
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="profile-button"
                                onClick={openRegistrationModal}
                                id="headerOrdersButton"
                            >
                                <IonIcon icon={personAddOutline} />
                                <span>Regisztráció</span>
                            </button>
                            <button
                                className="profile-button"
                                onClick={onLoginBtn}
                            >
                                <IonIcon icon={personCircleOutline} />
                                <span>Bejelentkezés</span>
                            </button>
                        </>
                    )}
                    <button className="cart-button" onClick={onCartBtn}>
                        <IonIcon icon={cartOutline} />
                        {cartItems.length > 0 && (
                            <span className="badge">
                                {cartItems.length ?? 0}
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        cartItems: state.CartReducer.cart.items,
    };
}

export default connect(mapStateToProps)(Header);
