import React from "react";
import { IonIcon, IonText } from "@ionic/react";
import { pencil, trash } from "ionicons/icons";
import { ModelShippingLocation } from "../../models/ShippingModel";

import "./LocationContent.scss";

interface Props {
    location: ModelShippingLocation;
    onEditButton?: () => void;
    onDeleteButton?: (shippingLocationId: number) => void;
    active?: boolean;
    clicakble?: boolean;
}

const LocationContent: React.FC<Props> = ({
    location,
    onEditButton,
    onDeleteButton,
    active,
    clicakble,
}) => {
    return (
        <>
            <div
                className={
                    "location-content" +
                    (active ? " active" : "") +
                    (clicakble ? " clicakble" : "")
                }
            >
                <span>
                    <b>
                        {location.service_location_not_found ?
                            (location.service_location_comment ?? "")
                            :
                            (location.service_location?.name ?? "")
                        }
                        {" "}
                        {location.old_address_imported_at !== null ?
                            (location.old_street ?? "")
                            :
                            (location.shipping_address ?? "")
                        }
                    </b>
                </span>
                {location.service_location_not_found === false && (
                    <span>
                        {location.service_location !== null &&
                            <b>
                                {location.service_location.service_area.postal_code ?? ""}{" "}
                                {location.service_location.service_area.city_name ?? ""}
                            </b>
                        }
                        {(location.old_address_imported_at !== null) &&
                            <b>
                                {location.old_postal_code ?? ""}{" "}
                                {location.old_city ?? ""}{" "}
                            </b>
                        }
                    </span>
                )}
                <br />
                {location.msoft_id !== null && (
                    <span>
                        Azonosító:
                        {" "}
                        <IonText color="primary">
                            <b>{location.msoft_id ?? ""}</b>
                        </IonText>
                    </span>
                )}
                {location.is_company && location.shipping_name && (
                    <span>Cégnév: {location.shipping_name ?? ""}</span>
                )}
                {location.clerk_name && (
                    <span>
                        {location.is_company ? "Ügyintéző neve:" : "Név:"}{" "}
                        {location.clerk_name ?? ""}
                    </span>
                )}
                {location.shipping_gate_phone && (
                    <span>
                        Kaputelefon: {location.shipping_gate_phone ?? ""}
                    </span>
                )}
                {location.shipping_phone_number && (
                    <span>Telefon: {location.shipping_phone_number ?? ""}</span>
                )}
                {location.shipping_comment && (
                    <span>Megjegyzés: {location.shipping_comment ?? ""}</span>
                )}
                {onEditButton !== undefined && (
                    <button
                        className="btn btn-primary btn-small edit-location"
                        onClick={onEditButton}
                    >
                        <IonIcon icon={pencil} slot="icon-only" />
                    </button>
                )}

                {onDeleteButton !== undefined && (
                    <button
                        className="btn btn-danger btn-small delete-location"
                        onClick={() => onDeleteButton(location.id)}
                    >
                        <IonIcon icon={trash} slot="icon-only" />
                    </button>
                )}

                {(location.old_address_imported_at !== null) &&
                    <div className="location-alert" onClick={onEditButton}>
                        A cím korrekciót igényel, kérem kattintson a javításhoz!
                    </div>
                }
            </div>
        </>
    );
};

export default LocationContent;
