import {
    IonButton,
    IonChip,
    IonCol,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRouterLink,
    IonRow,
    IonText,
} from "@ionic/react";
import { add, remove } from "ionicons/icons";
import moment, { weekdays } from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";
import {
    ProcessCartItems,
    SumCartItems,
    processCartItemsLikeApp,
} from "../helpers/functions/CartHelper";
import { PAYMENT_METHODS } from "../helpers/functions/CheckoutHelper";
import { ModelCartItem } from "../models/FoodModel";
import { ModelOrderData } from "../models/ShippingModel";
import { changeOrder } from "../services/CartService";
import { addAlert } from "../store/alert/Actions";
import { addToast } from "../store/toast/Actions";

import "./OrderInfo.scss";

interface Props {
    order: ModelOrderData;
    border: boolean;
    details: boolean;
    buttons: boolean;
    routerLink?: string;
    routerDirection?: "none" | "forward" | "back" | "root";
    onChangeOrCancel: () => void;
    onClick?: () => void;
}

const OrderInfo: React.FC<Props> = ({
    order,
    border,
    details,
    buttons,
    routerLink,
    routerDirection,
    onClick,
    onChangeOrCancel,
}) => {
    const dispatch = useDispatch();
    const [copyCartItems, setCopyCartItems] = useState<ModelCartItem[]>([
        ...order.cart.items,
    ]);

    useEffect(() => {
        setCopyCartItems([...order.cart.items]);
    }, [order]);

    const getStatus = () => {
        switch (order.payment_status) {
            default:
            case "order_fully_revoked":
                return (
                    <IonChip color="warning" outline={true}>
                        <IonLabel>Lemondva</IonLabel>
                    </IonChip>
                );
            case "order_placed":
            case "order_in_progress":
                return (
                    <IonChip color="warning" outline={true}>
                        <IonLabel>Folyamatban</IonLabel>
                    </IonChip>
                );
            case "order_unsuccessful":
                return (
                    <IonChip color="danger" outline={true}>
                        <IonLabel>Sikertelen</IonLabel>
                    </IonChip>
                );
            case "order_paid":
                if (order.payment_method === "cash") {
                    return (
                        <IonChip color="success" outline={true}>
                            <IonLabel>Fizetés futárnál</IonLabel>
                        </IonChip>
                    );
                } else {
                    return (
                        <IonChip color="success" outline={true}>
                            <IonLabel>Fizetve</IonLabel>
                        </IonChip>
                    );
                }
            case "order_canceled":
                return (
                    <IonChip color="danger" outline={true}>
                        <IonLabel>Megszakítva</IonLabel>
                    </IonChip>
                );
        }
    };

    const handleChangeAmount = (
        cart_items: ModelCartItem[],
        isPlus: boolean
    ) => {
        let tempCopyCartItems = [...copyCartItems];
        for (let i = 0; i < cart_items.length; i++) {
            let foundItemIndex = tempCopyCartItems.findIndex(
                (item) => item.menu_item.id === cart_items[i].menu_item.id
            );
            if (foundItemIndex > -1) {
                let currentTempItem = { ...tempCopyCartItems[foundItemIndex] };
                if (isPlus) {
                    const currentOrderItem = order.cart.items.find(
                        (item) =>
                            item.menu_item.id === cart_items[i].menu_item.id
                    );
                    if (currentOrderItem) {
                        if (
                            currentOrderItem.quantity > currentTempItem.quantity
                        ) {
                            currentTempItem.quantity += 1;
                            tempCopyCartItems[foundItemIndex] = currentTempItem;
                        } else {
                            dispatch(
                                addToast({
                                    message:
                                        "Az eredeti mennyiségnél nem lehet többet hozzáadni!",
                                    duration: 4000,
                                    color: "danger",
                                })
                            );
                        }
                    }
                } else {
                    if (currentTempItem.quantity >= 1) {
                        currentTempItem.quantity -= 1;
                        tempCopyCartItems[foundItemIndex] = currentTempItem;
                    }
                }
            }
        }
        setCopyCartItems(tempCopyCartItems);
    };

    const getCartChanges = (isChange: boolean): any[] => {
        let changes: any[] = [];
        if (isChange) {
            for (let i = 0; i < order.cart.items.length; i++) {
                const foundIndex = copyCartItems.findIndex(
                    (copyItem) =>
                        copyItem.menu_item.id ===
                        order.cart.items[i].menu_item.id
                );
                if (
                    foundIndex === -1 ||
                    copyCartItems[foundIndex].quantity !==
                        order.cart.items[i].quantity
                ) {
                    changes.push({
                        id: order.cart.items[i].id,
                        amount:
                            foundIndex === -1
                                ? 0
                                : copyCartItems[foundIndex].quantity,
                    });
                }
            }
        } else {
            for (let i = 0; i < order.cart.items.length; i++) {
                if (order.cart.items[i].quantity === 0) {
                    continue;
                }
                changes.push({
                    id: order.cart.items[i].id,
                    amount: 0,
                });
            }
        }
        return changes;
    };
    const handleOrderChanges = (isChange: boolean) => {
        let changeData = {
            cart_id: order.cart_id,
            cart_items: getCartChanges(isChange),
        };
        changeOrder(changeData)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a rendelés módosítása közben, kérjük próbálja újra később...",
                        null,
                        15000
                    )
                ) {
                    return;
                }
                onChangeOrCancel();
                dispatch(
                    addToast({
                        message: "A rendelés sikeresen módosítva!",
                        duration: 2000,
                        color: "success",
                    })
                );
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a rendelés módosítása közben, kérjük próbálja újra később...",
                    null,
                    15000
                );
            });
    };

    const handleOrderChangeAlert = (isChange: boolean) => {
        dispatch(
            addAlert({
                message:
                    "Biztosan " +
                    (isChange ? "módosítani szeretné" : "le szeretné mondani") +
                    " a rendelését?",
                title: "Rendelés " + (isChange ? "módosítás" : "lemondás"),
                buttons: [
                    {
                        text: "Mégse",
                        role: "cancel",
                    },
                    {
                        text: isChange ? "Módosítás" : "Lemondás",
                        handler: () => handleOrderChanges(isChange),
                    },
                ],
            })
        );
    };

    const getItems = (): any => {
        // let tempElements: {
        //   name: string;
        //   items: ModelCartItem[];
        // }[] = [];
        // let dailyElements = copyCartItems.filter((item) => !item.category.weekly_menu_of_category_id);
        // for (let i = 0; i < dailyElements.length; i++) {
        //   tempElements.push({
        //     name: dailyElements[i].menu_item.food.display_name ?? dailyElements[i].menu_item.food.name,
        //     items: [dailyElements[i]],
        //   });
        // }
        // let groups = copyCartItems
        //   .filter((item) => item.category.weekly_menu_of_category_id)
        //   .reduce((groups: { [key: string]: ModelCartItem[] }, item: ModelCartItem) => {
        //     groups[item.category.name] = [...(groups[item.category.name] || []), item];
        //     return groups;
        //   }, {});
        // for (const [key, value] of Object.entries(groups)) {
        //   tempElements.push({
        //     name: key,
        //     items: value,
        //   });
        // }
        // return tempElements;
        if (order.cart) {
            return processCartItemsLikeApp(order.cart);
        } else {
            return [];
        }
    };

    const getMenuWeeks = () => {
        let weeks: number[] = [];
        order.cart.items.forEach((item: ModelCartItem, index: number) => {
            const week = moment(item.menu_item.date).week();
            if (!weeks.includes(week)) {
                weeks.push(week);
            }
        });
        return weeks.join(", ");
    };

    const getInsideContent = () => {
        return (
            <div className={"orderData-div" + (border ? " hasBorder" : "")}>
                <div className="multiLine">
                    <span>Rendelés azonosító</span>
                    <span>
                        <IonText color="primary">
                            <b>{order.id ?? "-"}</b>
                        </IonText>
                    </span>
                </div>
                <div className="multiLine">
                    <span>Ügyfél azonosító</span>
                    <span>
                        <IonText color="warning">
                            <b>{order?.msoft_id ?? "-"}</b>
                        </IonText>
                    </span>
                </div>
                <div className="multiLine">
                    <span>Rendelte</span>
                    <span>{order.shipping_name ?? " - "}</span>
                </div>
                <div className="multiLine">
                    <span>Fiezetés</span>
                    <span>
                        {order.payment_method !== "" && (
                            <>
                                {PAYMENT_METHODS[order.payment_method].label ??
                                    " - "}
                            </>
                        )}
                    </span>
                </div>
                <div className="multiLine">
                    <span>Státusz</span>
                    <span>{getStatus()}</span>
                </div>
                <div className="multiLine">
                    <span>Cím</span>
                    <span>
                        {order.service_location?.service_area?.postal_code ??
                            " - "}{" "}
                        {order.service_location?.service_area?.city_name ??
                            " - "}
                        <br />
                        {order.service_location?.name ?? ""}{" "}
                        {order.shipping_address ?? ""}
                    </span>
                </div>
                <div className="multiLine">
                    <span>Hét</span>
                    <span>{getMenuWeeks()}</span>
                </div>
                {details && order.created_at !== null && (
                    <div className="multiLine">
                        <span>Leadva</span>
                        <span>
                            {moment(order.created_at).format(
                                "YYYY.MM.DD. hh:mm"
                            ) ?? ""}
                        </span>
                    </div>
                )}
                <div className="multiLine">
                    <span>Végösszeg</span>
                    <NumberFormat
                        isNumericString={true}
                        value={parseFloat(order.total.toString())}
                        displayType={"text"}
                        thousandSeparator={" "}
                        suffix={" Ft"}
                    />
                </div>
                <div className="multiLine">
                    <span>Kedvezmény</span>
                    <span>{order.real_total - order.total} Ft</span>
                </div>
                {order.coupon && (
                    <div className="multiLine">
                        <span>Felhasznált kupon</span>
                        <span>{order.coupon.coupon_code ?? ""}</span>
                    </div>
                )}
                {order.person ? (
                    <div className="multiLine">
                        <span>Személy</span>
                        <span>
                            <span
                                style={{ background: order.person.color }}
                                className="avatar-bubble"
                            >
                                {order.person.name[0]}
                            </span>
                            {order.person.name ?? ""}
                        </span>
                    </div>
                ) : null}
                {details && (
                    <div className="multiLine details table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Étel</th>
                                    <th>Kategória</th>
                                    <th>Dátum</th>
                                    <th>Mennyiség</th>
                                    <th>Ár</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getItems().map(
                                    (weekData: any, key: number) => {
                                        return (
                                            <React.Fragment
                                                key={
                                                    "week-container-" +
                                                    key +
                                                    "-" +
                                                    weekData.week
                                                }
                                            >
                                                <tr
                                                    key={
                                                        "week-" +
                                                        key +
                                                        "-" +
                                                        weekData.week
                                                    }
                                                >
                                                    <th
                                                        colSpan={5}
                                                        className="weekNum"
                                                    >
                                                        {weekData.week}. hét
                                                    </th>
                                                </tr>
                                                {Object.keys(
                                                    weekData.weekly ?? {}
                                                ).map((weeklyItemKey: any) => (
                                                    <tr
                                                        key={
                                                            weekData.week +
                                                            "-weekly-item-" +
                                                            weeklyItemKey
                                                        }
                                                    >
                                                        <td>
                                                            {weekData.weekly[
                                                                weeklyItemKey
                                                            ][0]?.category
                                                                ?.name ?? ""}
                                                        </td>
                                                        <td>
                                                            {weeklyItemKey +
                                                                " - " +
                                                                (weekData
                                                                    .weekly[
                                                                    weeklyItemKey
                                                                ][0]?.category
                                                                    ?.name ??
                                                                    "")}
                                                        </td>
                                                        <td></td>
                                                        <td className="orderChangeButtons">
                                                            {order.payment_status ===
                                                                "order_paid" && (
                                                                <button
                                                                    onClick={() =>
                                                                        handleChangeAmount(
                                                                            copyCartItems.filter(
                                                                                (
                                                                                    x
                                                                                ) =>
                                                                                    weekData.weekly[
                                                                                        weeklyItemKey
                                                                                    ]
                                                                                        .map(
                                                                                            (
                                                                                                y: any
                                                                                            ) =>
                                                                                                y
                                                                                                    .menu_item
                                                                                                    ?.id ??
                                                                                                -1
                                                                                        )
                                                                                        .includes(
                                                                                            x
                                                                                                .menu_item
                                                                                                ?.id ??
                                                                                                -2
                                                                                        )
                                                                            ),
                                                                            false
                                                                        )
                                                                    }
                                                                    className="btn btn-medium btn-sm"
                                                                >
                                                                    <IonIcon
                                                                        icon={
                                                                            remove
                                                                        }
                                                                    />
                                                                </button>
                                                            )}
                                                            <span className="Quantity">
                                                                {copyCartItems.find(
                                                                    (x) =>
                                                                        weekData.weekly[
                                                                            weeklyItemKey
                                                                        ]
                                                                            .map(
                                                                                (
                                                                                    y: any
                                                                                ) =>
                                                                                    y
                                                                                        .menu_item
                                                                                        ?.id ??
                                                                                    -1
                                                                            )
                                                                            .includes(
                                                                                x
                                                                                    .menu_item
                                                                                    ?.id ??
                                                                                    -2
                                                                            )
                                                                )?.quantity ??
                                                                    1}
                                                            </span>
                                                            {order.payment_status ===
                                                                "order_paid" && (
                                                                <button
                                                                    onClick={() =>
                                                                        handleChangeAmount(
                                                                            copyCartItems.filter(
                                                                                (
                                                                                    x
                                                                                ) =>
                                                                                    weekData.weekly[
                                                                                        weeklyItemKey
                                                                                    ]
                                                                                        .map(
                                                                                            (
                                                                                                y: any
                                                                                            ) =>
                                                                                                y
                                                                                                    .menu_item
                                                                                                    ?.id ??
                                                                                                -1
                                                                                        )
                                                                                        .includes(
                                                                                            x
                                                                                                .menu_item
                                                                                                ?.id ??
                                                                                                -2
                                                                                        )
                                                                            ),
                                                                            true
                                                                        )
                                                                    }
                                                                    className="btn btn-medium btn-sm"
                                                                >
                                                                    <IonIcon
                                                                        icon={
                                                                            add
                                                                        }
                                                                    />
                                                                </button>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {SumCartItems(
                                                                copyCartItems.filter(
                                                                    (x) =>
                                                                        weekData.weekly[
                                                                            weeklyItemKey
                                                                        ]
                                                                            .map(
                                                                                (
                                                                                    y: any
                                                                                ) =>
                                                                                    y
                                                                                        .menu_item
                                                                                        ?.id ??
                                                                                    -1
                                                                            )
                                                                            .includes(
                                                                                x
                                                                                    .menu_item
                                                                                    ?.id ??
                                                                                    -2
                                                                            )
                                                                )
                                                            )}{" "}
                                                            Ft
                                                        </td>
                                                    </tr>
                                                ))}
                                                {(weekData.daily ?? []).map(
                                                    (dayData: any) => (
                                                        <React.Fragment
                                                            key={
                                                                "day-" +
                                                                dayData.date
                                                            }
                                                        >
                                                            {(
                                                                dayData.items ??
                                                                []
                                                            ).map(
                                                                (
                                                                    dailyItem: any
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            weekData.week +
                                                                            "-daily-item-" +
                                                                            dailyItem.id
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {dailyItem
                                                                                .menu_item
                                                                                ?.food
                                                                                ?.display_name ??
                                                                                dailyItem
                                                                                    .menu_item
                                                                                    ?.food
                                                                                    ?.name}
                                                                        </td>
                                                                        <td>
                                                                            {dailyItem
                                                                                .menu_item
                                                                                .category
                                                                                .code +
                                                                                " - " +
                                                                                dailyItem
                                                                                    .menu_item
                                                                                    .category
                                                                                    .name}
                                                                        </td>
                                                                        <td>
                                                                            {moment(
                                                                                dayData.date
                                                                            ).format(
                                                                                "YYYY.MM.DD. - dddd"
                                                                            )}
                                                                        </td>
                                                                        <td className="orderChangeButtons">
                                                                            {order.payment_status ===
                                                                                "order_paid" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleChangeAmount(
                                                                                            copyCartItems.filter(
                                                                                                (
                                                                                                    x
                                                                                                ) =>
                                                                                                    x.id ===
                                                                                                    dailyItem.id
                                                                                            ),
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-medium btn-sm"
                                                                                >
                                                                                    <IonIcon
                                                                                        icon={
                                                                                            remove
                                                                                        }
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                            <span className="Quantity">
                                                                                {copyCartItems.find(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.id ===
                                                                                        dailyItem.id
                                                                                )
                                                                                    ?.quantity ??
                                                                                    1}
                                                                            </span>
                                                                            {order.payment_status ===
                                                                                "order_paid" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleChangeAmount(
                                                                                            copyCartItems.filter(
                                                                                                (
                                                                                                    x
                                                                                                ) =>
                                                                                                    x.id ===
                                                                                                    dailyItem.id
                                                                                            ),
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-medium btn-sm"
                                                                                >
                                                                                    <IonIcon
                                                                                        icon={
                                                                                            add
                                                                                        }
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {SumCartItems(
                                                                                copyCartItems.filter(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.id ===
                                                                                        dailyItem.id
                                                                                )
                                                                            )}{" "}
                                                                            Ft
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {routerLink && routerDirection && onClick ? (
                <IonRouterLink
                    routerLink={routerLink}
                    routerDirection={routerDirection}
                    onClick={onClick}
                    className={"orderData"}
                >
                    {getInsideContent()}
                </IonRouterLink>
            ) : (
                <>{getInsideContent()}</>
            )}

            {buttons && (
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton
                                color="medium"
                                routerDirection="root"
                                routerLink="/"
                                expand="block"
                            >
                                Vissza az étlapra
                            </IonButton>
                        </IonCol>
                        {order.payment_status === "order_paid" && (
                            <>
                                <IonCol>
                                    <IonButton
                                        color="medium"
                                        expand="block"
                                        onClick={() =>
                                            handleOrderChangeAlert(false)
                                        }
                                    >
                                        Rendelés lemondása
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton
                                        color="danger"
                                        disabled={
                                            getCartChanges(true).length === 0
                                        }
                                        onClick={() =>
                                            handleOrderChangeAlert(true)
                                        }
                                        expand="block"
                                    >
                                        Módosítások mentése
                                    </IonButton>
                                </IonCol>
                            </>
                        )}
                        {order.gateway_url !== null && (
                            <IonCol>
                                <IonButton
                                    color="danger"
                                    href={order.gateway_url}
                                    expand="block"
                                >
                                    Újrafizetés
                                </IonButton>
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            )}
        </>
    );
};

export default OrderInfo;
