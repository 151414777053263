import React from "react";
import { ModelCategory } from "../models/CategoryModel";
import { ModelMenuItem, ModelBanner, ModelCartItem } from "../models/FoodModel";
import Food from "./Food";
import CategoryBanner from "../components/CategoryBanner";
import { ADD_TO_CART, REMOVE_FROM_CART } from "../store/cart/ActionTypes";
import { connect } from "react-redux";
import { RootState } from "../store/RootReducer";
import moment from "moment";
import add from "../assets/images/add.svg";
import remove from "../assets/images/remove.svg";
import { categoryDataToCart } from "../helpers/functions/CartHelper";
import classnames from "classnames";

interface StateFromReducer {
    onAddToCart?: any;
    onRemoveFromCart?: any;
}
interface DispatchFromReducer {
    quantity?: number;
}
type Props = StateFromReducer &
    DispatchFromReducer & {
        category: ModelCategory;
        banner: ModelBanner;
        version: number|null;
        lastMinute?: boolean;
    };

const Category: React.FC<Props> = ({
    category,
    banner,
    onAddToCart,
    onRemoveFromCart,
    quantity,
    version,
    lastMinute = false,
}) => {
    const weeklyCategory: ModelCategory = (category.weekly_menus && category.weekly_menus.length > 0) ? category.weekly_menus[0] : null;
    const firstValidIndex: number|null = (category.weekly_menus && category.weekly_menus.length > 0) ? category.weekly_menus[0].menu_items.findIndex((e: any) => e !== undefined) : null;

    const getWeeklyMenuPrice = () => {
        return weeklyCategory.menu_items.reduce(
            (total: number, item: ModelMenuItem) => {
                return total + (item?.price ?? 0);
            },
            0
        );
    };

    const getFoodComponent = (menu_item: ModelMenuItem, index: number) => {
        if (menu_item !== undefined) {
            if (!menu_item.date) {
                return null;
            }
            return (
                <Food
                    key={category.id + "_" + menu_item.id}
                    date={menu_item.date}
                    menu_item={menu_item}
                    index={index}
                    category={category}
                    version={version}
                    lastMinute={lastMinute}
                />
            );
        } else {
            if (lastMinute) {
                return <></>;
            }
            return (
                <div
                    className={"Food EmptyFood"}
                    key={category.id + "_nofood_" + index}
                    data-index={index}
                />
            );
        }
    };

    const handleWeeklyMenuCartChange = (isPlus: boolean) => {
        if (isPlus) {
            onAddToCart(
                weeklyCategory.menu_items.filter((item) => item !== undefined),
                categoryDataToCart(weeklyCategory)
            );
        } else {
            onRemoveFromCart(
                weeklyCategory.menu_items.filter((item) => item !== undefined),
                categoryDataToCart(weeklyCategory)
            );
        }
    };

    return (
        <>
            {banner ? <CategoryBanner banner={banner} /> : null}
            {category.menu_items.filter(
                (menu_item: any, _: number) => menu_item === undefined
            ).length < 6 && (
                <div className="Category">
                    <div
                        className="CategoryCode"
                        style={{
                            background: category.background_color,
                            color: category.food_text_color,
                        }}
                    >
                        {category.code ?? ""}
                    </div>
                    <div
                        className="CategoryName"
                        style={{
                            background: category.background_color,
                            color: category.food_text_color,
                        }}
                    >
                        {category.name ?? ""}
                        {weeklyCategory?.menu_items && weeklyCategory.menu_items.length > 0 && weeklyCategory.menu_items[firstValidIndex].disabled === false ? (
                            <>
                                {" "}
                                {getWeeklyMenuPrice()} Ft
                                <div className="weeklyButtons">
                                    <button
                                        onClick={() =>
                                            handleWeeklyMenuCartChange(
                                                false
                                            )
                                        }
                                        className="btn btn-medium btn-sm"
                                    >
                                        <img src={remove} alt="Minusz" />
                                    </button>
                                    <span className="Quantity">
                                        {quantity ?? 0}
                                    </span>
                                    <button
                                        onClick={() =>
                                            handleWeeklyMenuCartChange(true)
                                        }
                                        className="btn btn-medium btn-sm"
                                    >
                                        <img src={add} alt="Plusz" />
                                    </button>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className={classnames("Foods", lastMinute ? "lastMinute" : "")}>
                        {category.menu_items.map(
                            (menu_item: ModelMenuItem, index: number) =>
                                getFoodComponent(menu_item, index)
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

function mapStateToProps(state: RootState, ownProps: Props) {
    let firstValidIndex = (ownProps.category.weekly_menus && ownProps.category.weekly_menus.length > 0) ? ownProps.category.weekly_menus[0].menu_items.findIndex((element: any) => element !== undefined) : null;
    if (
        ownProps.category &&
        ownProps.category.weekly_menus &&
        ownProps.category.weekly_menus.length > 0
    ) {
        const foundedCartItem = state.CartReducer.cart.items.find(
            (cartItem: ModelCartItem) =>
                cartItem.menu_item.id ===
                ownProps.category.weekly_menus[0].menu_items[firstValidIndex].id
        );

        if (foundedCartItem) {
            return {
                quantity: foundedCartItem.quantity,
            };
        } else {
            return { quantity: 0 };
        }
    } else {
        return { quantity: 0 };
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        onAddToCart: (
            menu_item: ModelMenuItem[],
            category: {
                name: string;
                background_color: string;
                font_color: string;
                weekly_menu_of_category_id: number | null;
            }
        ) =>
            dispatch({
                type: ADD_TO_CART,
                payload: { menu_item, category },
            }),
        onRemoveFromCart: (
            menu_item: ModelMenuItem[],
            category: {
                name: string;
                background_color: string;
                font_color: string;
                weekly_menu_of_category_id: number | null;
            }
        ) =>
            dispatch({
                type: REMOVE_FROM_CART,
                payload: { menu_item, category },
            }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Category);
