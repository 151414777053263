import React, { useEffect } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonSpinner,
} from "@ionic/react";
import { add, location } from "ionicons/icons";
import { ModelShippingLocation } from "../../models/ShippingModel";
import { useDispatch } from "react-redux";
import { setAddressModal } from "../../store/modals/address/Actions";
import LocationContent from "../../components/checkout/LocationContent";

interface Props {
    shippingLocations: {
        shippingLocationsLoaded: boolean;
        shippingLocations: Array<ModelShippingLocation>;
    };
    selectedShippingLocation: number | null;
    fetchShippingLocations: any;
    handleAddressDelete: (id: number | null) => void;
    handleSelectedShippingLocationChange: (value: any) => void;
}

const AddressCard: React.FC<Props> = ({
    shippingLocations,
    selectedShippingLocation,
    fetchShippingLocations,
    handleAddressDelete,
    handleSelectedShippingLocationChange,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (shippingLocations.shippingLocationsLoaded && shippingLocations.shippingLocations.length === 1) {
            handleSelectedShippingLocationChange(
                shippingLocations.shippingLocations[0].id
            );
        }
    }, [shippingLocations]);

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle className="justify">
                    <div>
                        <span className="checkoutBadge">
                            <IonIcon icon={location} />
                        </span>
                        Szállítás
                    </div>
                    <div>
                        <IonButton
                            size="small"
                            onClick={() =>
                                dispatch(
                                    setAddressModal(true, null, () =>
                                        fetchShippingLocations(true)
                                    )
                                )
                            }
                        >
                            <IonIcon slot="start" icon={add} />
                            Új cím
                        </IonButton>
                    </div>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {shippingLocations.shippingLocationsLoaded ? (
                    <>
                        {shippingLocations.shippingLocations.length > 0 ? (
                            <>
                                {selectedShippingLocation === null && (
                                    <>
                                        <div className="ion-text-center">
                                            <p>
                                                Kattintson a cím
                                                kiválasztásához.
                                            </p>
                                        </div>
                                        <br />
                                    </>
                                )}
                                <div
                                    className="boxRadio twoColumns"
                                    id="locationGroup"
                                >
                                    {shippingLocations.shippingLocations.map(
                                        (
                                            location: ModelShippingLocation,
                                            index: number
                                        ) => (
                                            <label
                                                className={
                                                    selectedShippingLocation ===
                                                    location.id
                                                        ? "active"
                                                        : ""
                                                }
                                                key={index}
                                            >
                                                <input
                                                    type="radio"
                                                    value={location.id}
                                                    checked={
                                                        selectedShippingLocation ===
                                                        location.id
                                                    }
                                                    onChange={(e: any) =>
                                                        handleSelectedShippingLocationChange(
                                                            parseInt(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                                <LocationContent
                                                    location={location}
                                                    onEditButton={() => {
                                                        dispatch(
                                                            setAddressModal(true, location, () => fetchShippingLocations())
                                                        );
                                                    }}
                                                    onDeleteButton={() =>
                                                        handleAddressDelete(location.id)
                                                    }
                                                    active={selectedShippingLocation === location.id}
                                                    clicakble={true}
                                                />
                                            </label>
                                        )
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="ion-text-center">
                                <p>Még nem adott hozzá szállítási címet...</p>
                                <br />
                                <IonButton
                                    size="small"
                                    onClick={() =>
                                        dispatch(
                                            setAddressModal(true, null, () =>
                                                fetchShippingLocations(true)
                                            )
                                        )
                                    }
                                >
                                    <IonIcon slot="start" icon={add} />
                                    Új szállítási cím
                                </IonButton>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="ion-text-center">
                        <IonSpinner />
                    </div>
                )}
            </IonCardContent>
        </IonCard>
    );
};
export default AddressCard;
