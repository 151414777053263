import React from "react";
import { IonImg } from "@ionic/react";
import { ModelBanner } from "../models/FoodModel";
import { addAnalytics } from "../services/AnalyticsService";

import "./CategoryBanner.scss";

type Props = {
    banner: ModelBanner;
};

const CategoryBanner: React.FC<Props> = ({ banner }) => {
    const handleBannerClick = () => {
        if (banner.open_url) {
            addAnalytics("banner", "click", "App\\Models\\Banner", banner.id);
            window.open(banner.open_url, "_blank ");
        }
    };

    return (
        <div
            className={
                "CategoryBanner" +
                (banner.full_width ? " fullWidth" : "") +
                (banner.show_on_mobile ? " showOnMobile" : "") +
                (banner.open_url ? " openUrl" : "")
            }
        >
            {banner.full_width === false && (
                <>
                    <div></div>
                    <div></div>
                </>
            )}
            {banner.image !== null ? (
                <>
                    <IonImg
                        style={{ cursor: (banner.open_url ? "pointer" : "default") }}
                        onClick={handleBannerClick}
                        src={
                            `${process.env.REACT_APP_FILE_URL}/` +
                            banner.image.uuid
                        }
                        className="CategoryBannerImage desktop"
                    />
                    {banner.mobile_image !== null && (
                        <IonImg
                            style={{ cursor: "pointer" }}
                            onClick={handleBannerClick}
                            src={
                                `${process.env.REACT_APP_FILE_URL}/` +
                                banner.mobile_image.uuid
                            }
                            className="CategoryBannerImage mobile"
                        />
                    )}
                </>
            ) : (
                <div
                    className="CategoryBannerText"
                    style={{
                        background: banner.bg_color,
                        color: banner.font_color,
                    }}
                    onClick={handleBannerClick}
                >
                    {banner.frontend_title && (
                        <h3>{banner.frontend_title ?? ""}</h3>
                    )}
                    {banner.frontend_text && (
                        <p>{banner.frontend_text ?? ""}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default CategoryBanner;
