import moment from "moment";
import { ModelCategory } from "../../models/CategoryModel";
import { ModelCartItem } from "../../models/FoodModel";
import { getWeek, getYear, parseISO } from "date-fns";

export const ProcessCartItems = (cart: any) => {
    let groupsByDate = cart.items.reduce((groups: any, item: any) => {
        const date = item.date;
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(item);
        return groups;
    }, {});

    // Edit: to add it in the array format instead
    let groupsByDateArray = Object.keys(groupsByDate).map((date) => {
        return {
            date,
            dailyItems: groupsByDate[date].filter(
                (item: any) => !item.category.weekly_menu_of_category_id
            ),
            weeklyItems: groupsByDate[date].filter(
                (item: any) => item.category.weekly_menu_of_category_id
            ),
        };
    });

    groupsByDateArray = groupsByDateArray.sort((a: any, b: any) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    let groupsByWeek = groupsByDateArray.reduce((groups: any, item: any) => {
        const week = moment(item.date).week();
        const year = moment(item.date).year(); // jó nagy TODO
        if (!groups[year.toString() + week.toString()]) {
            groups[year.toString() + week.toString()] = [];
        }
        groups[year.toString() + week.toString()].push(item);
        return groups;
    }, {});

    const getWeeklyItems = (daysData: any[]) => {
        let WeeklyItems = [];
        for (let i = 0; i < daysData.length; i++) {
            if (daysData[i].weeklyItems && daysData[i].weeklyItems.length > 0) {
                WeeklyItems.push(daysData[i].weeklyItems);
            }
        }
        return WeeklyItems.flat();
    };

    // Edit: to add it in the array format instead
    return Object.keys(groupsByWeek).map((weekData) => {
        const year = parseInt(weekData.substr(0, 4));
        const week = parseInt(weekData.substr(4));
        return {
            week: week,
            year: year,
            daysData: groupsByWeek[year.toString() + week.toString()],
            weeklyItems: getWeeklyItems(
                groupsByWeek[year.toString() + week.toString()]
            ),
        };
    });
};

// APP LIKE, nem lett túlbonyolítva, copyztam mindent az appos verzióból, hogy teljesen ugyanaz legyen
// később majd valami fejlesztéshez hozzá kell csapni ennek a rendbe rakását
export interface IFood {
    comment: string | null;
    display_name: string;
    dont_watermark: number; // tinyInt
    food_rating_cache: number | null;
    id: number;
    image_id: number | null;
    last_occurence_menu_item_id: number | null;
    last_occured_menu_item?: IMenuItem;
    name: string;
    small_image_id: number | null;
    users: [];
}

export interface IMenuIcon {
    id: number;
    name: string;
    open_url: string | null;
    tooltip: string | null;
    position: string | null;
    image: IImage | null;
}

export interface IImage {
    id: number;
    uuid: string;
}
export interface IMenuCategory {
    background_color: string | null;
    big_portion_category_id: number | null;
    code: string;
    description: string | null;
    font_color: string;
    food_bg_color: string;
    food_text_color: string;
    id: number;
    items: IMenuItem[];
    menu_category_group_id: number | null;
    name: string;
    sequence: number;
    show_code_name: string | null;
    weekly_menu_of_category_id: number | null;
    weekly_menus: IMenuCategory[];
}

export interface IMenuItem {
    cancel_deadline: Date | null;
    carb_hundred_food_one: number | null;
    carb_hundred_food_three: number | null;
    carb_hundred_food_two: number | null;
    carb_portion_food_one: number | null;
    carb_portion_food_three: number | null;
    carb_portion_food_two: number | null;
    comment: string;
    date: string;
    description: string;
    disabled: boolean;
    energy_hundred_food_one: number | null;
    energy_hundred_food_three: number | null;
    energy_hundred_food_two: number | null;
    energy_portion_food_one: number | null;
    energy_portion_food_three: number | null;
    energy_portion_food_two: number | null;
    fat_hundred_food_one: number | null;
    fat_hundred_food_three: number | null;
    fat_hundred_food_two: number | null;
    fat_portion_food_one: number | null;
    fat_portion_food_three: number | null;
    fat_portion_food_two: number | null;
    food_name_one: string;
    food_name_three: string;
    food_name_two: string;
    food: IFood;
    id: number;
    is_last_minute_orderable: boolean;
    lm_can_be_ordered_from: Date | null;
    lm_can_be_ordered_to: Date | null;
    lm_leftover_quantity: number | null;
    lm_preorder_quantity: number | null;
    lm_set_leftover_from: Date | null;
    lm_set_leftover_to: Date | null;
    lm_set_preorder_from: Date | null;
    lm_set_preorder_to: Date | null;
    lm_sold_quantity: number | null;
    category_id: number | null;
    category: IMenuCategory;
    menu_icons: IMenuIcon[];
    order_disabled_at: Date | null;
    order_disabled_quota_at: Date | null;
    price: number;
    protein_hundred_food_one: number | null;
    protein_hundred_food_three: number | null;
    protein_hundred_food_two: number | null;
    protein_portion_food_one: number | null;
    protein_portion_food_three: number | null;
    protein_portion_food_two: number | null;
    salt_hundred_food_one: number | null;
    salt_hundred_food_three: number | null;
    salt_hundred_food_two: number | null;
    salt_portion_food_one: number | null;
    salt_portion_food_three: number | null;
    salt_portion_food_two: number | null;
    saturated_fat_hundred_food_one: number | null;
    saturated_fat_hundred_food_three: number | null;
    saturated_fat_hundred_food_two: number | null;
    saturated_fat_portion_food_one: number | null;
    saturated_fat_portion_food_three: number | null;
    saturated_fat_portion_food_two: number | null;
    sugar_hundred_food_one: number | null;
    sugar_hundred_food_three: number | null;
    sugar_hundred_food_two: number | null;
    sugar_portion_food_one: number | null;
    sugar_portion_food_three: number | null;
    sugar_portion_food_two: number | null;
    vat: number | null;
    vat_food_one: number | null;
    vat_food_three: number | null;
    vat_food_two: number | null;
    weight_food_one: number | null;
    weight_food_three: number | null;
    weight_food_two: number | null;
}

export interface ICartItem {
    cart_id?: number;
    id: number;
    menu_item: IMenuItem;
    menu_item_id: number;
    original_amount?: number | null;
    previous_quantity?: number | null;
    quantity: number;
}

export interface ICart {
    items: ICartItem[];
}

interface IGroupByDate {
    [key: string]: ICartItem[];
}

export interface IGroupByDateArray {
    date: string;
    items: ICartItem[];
}

export interface IProcessedCartWeek {
    week: number;
    year: number;
    daily: IGroupByDateArray[];
    weekly: any;
}

export const processCartItemsLikeApp = (cart: any) => {
    // Ez a funckió azért felelős, hogy a megjelenítés előtt a megfelelő csoportokba rakja az itemeket.
    // A végén úgy kell kinéznie, hogy csoportosítjuk hetekre őket először,
    // majd napokra. Amely pedig heti menü azt a hét alá vesszük fel.
    const groupCartItemsByDate: IGroupByDate = cart.items.reduce(
        (group: IGroupByDate, cart_item: ICartItem) => {
            // Csak akkor rakjuk a date helyekre ha ez nem hetimenü item, azokat később kezelem le
            if (
                cart_item.menu_item.category.weekly_menu_of_category_id === null
            ) {
                const date = cart_item.menu_item.date;
                if (!group[date]) {
                    group[date] = [];
                }

                group[date].push(cart_item);
            }
            return group;
        },
        {}
    );

    let groupCartWeeklyItems: any = [];
    for (const item of cart.items) {
        if (item.menu_item.category.weekly_menu_of_category_id === null) {
            continue;
        }

        const date = item.menu_item.date;
        const week = getWeek(parseISO(date));
        const year = getYear(parseISO(date));
        const combined = year.toString() + "-" + week.toString();
        const code = item.menu_item.category.code;
        if (groupCartWeeklyItems[combined] === undefined) {
            groupCartWeeklyItems[combined] = [];
        }
        if (groupCartWeeklyItems[combined][code] === undefined) {
            groupCartWeeklyItems[combined][code] = [];
        }
        groupCartWeeklyItems[combined][code].push(item);
    }

    const groupsByDateArray: IGroupByDateArray[] = Object.keys(
        groupCartItemsByDate
    )
        .map((date: string) => {
            return {
                date,
                items: groupCartItemsByDate[date],
            };
        })
        .sort((a: IGroupByDateArray, b: IGroupByDateArray) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        });

    const groupsByWeek: any = groupsByDateArray.reduce(
        (groups: any, item: IGroupByDateArray) => {
            const week = getWeek(parseISO(item.date));
            const year = getYear(parseISO(item.date));
            const combined = year.toString() + "-" + week.toString();
            if (groups[combined] === undefined) {
                groups[combined] = [];
            }
            groups[combined].push(item);
            return groups;
        },
        {}
    );

    return Object.keys(groupsByWeek).map((weekData) => {
        const year = parseInt(weekData.substr(0, 4));
        const week = parseInt(weekData.substr(5));
        const combined = year.toString() + "-" + week.toString();
        return {
            week: week,
            year: year,
            daily: groupsByWeek[combined],
            weekly: groupCartWeeklyItems[combined],
        };
    });
};

export const SumCartItems = (cartItems: ModelCartItem[]) => {
    return cartItems.reduce((tempDayTotal: number, tempDayData: any) => {
        return (
            tempDayTotal + tempDayData.quantity * tempDayData.menu_item.price
        );
    }, 0);
};

export const categoryDataToCart = (category: ModelCategory) => {
    return {
        name: category.name,
        background_color: category.background_color,
        font_color: category.font_color,
        weekly_menu_of_category_id: category.weekly_menu_of_category_id,
        code: category.code,
        watermark_id: category.watermark_id,
        big_portion_category_id: category.big_portion_category_id,
    };
};
