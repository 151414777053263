import React from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonIcon,
    IonItem,
    IonLabel,
    IonSpinner,
} from "@ionic/react";
import { card } from "ionicons/icons";
import { ModelPaymentMethod } from "../../models/ShippingModel";
import PaymentContent from "../../components/checkout/PaymentContent";
import { PAYMENT_METHODS } from "../../helpers/functions/CheckoutHelper";

interface Props {
    selectedShippingLocation: number;
    paymentMethods: {
        paymentMethodsLoaded: boolean;
        paymentMethods: Array<ModelPaymentMethod>;
    };
    selectedPaymentMethod: string;
    cartItemsByWeek: any[];
    aszf: boolean;
    cartItemsByWeekLength: number;
    handleAszfChange: () => void;
    handleOrderComplete: () => void;
    handleSelectedPaymentMethodChange: (value: any) => void;
    needsShipping: boolean;
}

const PaymentMethodsCard: React.FC<Props> = ({
    selectedShippingLocation,
    paymentMethods,
    selectedPaymentMethod,
    aszf,
    cartItemsByWeekLength,
    handleAszfChange,
    handleOrderComplete,
    handleSelectedPaymentMethodChange,
    needsShipping
}) => {
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>
                    <span className="checkoutBadge">
                        <IonIcon icon={card} />
                    </span>
                    Fizetés
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {(needsShipping && selectedShippingLocation === null) && (
                    <div className="ion-text-center">
                        <p>Válasszon szállítási címet a "Szállítás" dobozból.</p>
                        <br />
                    </div>
                )}
                {paymentMethods.paymentMethodsLoaded ? (
                    <div className="boxRadio">
                        {paymentMethods.paymentMethods.map(
                            (method: any, index: number) => (
                                <label
                                    className={
                                        (selectedPaymentMethod === method.name
                                            ? "active"
                                            : "") +
                                        (method.enabled === false
                                            ? " disabled"
                                            : "")
                                    }
                                    key={index}
                                >
                                    <input
                                        type="radio"
                                        value={method.name}
                                        checked={
                                            selectedPaymentMethod ===
                                            method.name
                                        }
                                        onChange={(e: any) =>
                                            handleSelectedPaymentMethodChange(
                                                e.target.value
                                            )
                                        }
                                        disabled={method.enabled === false}
                                    />
                                    <PaymentContent
                                        method={method}
                                        icon={PAYMENT_METHODS[method.name].icon}
                                        label={
                                            PAYMENT_METHODS[method.name].label
                                        }
                                        active={
                                            selectedPaymentMethod ===
                                            method.name
                                        }
                                    />
                                </label>
                            )
                        )}
                    </div>
                ) : (
                    <div className="ion-text-center">
                        <IonSpinner />
                    </div>
                )}

                <br />
                <IonItem
                    lines="none"
                    className={"checkbox-input item item-text-wrap"}
                >
                    <IonCheckbox
                        title="Adatkezelési nyilatkozat"
                        onIonChange={handleAszfChange}
                        name="aszf"
                        checked={aszf}
                        data-testid="input-aszf"
                        id="input-aszf"
                    />
                    <IonLabel
                        style={{
                            whiteSpace: "normal",
                            fontSize: 14,
                        }}
                    >
                        Ezúton nyilatkozom, hogy<br/>
                        - Megértettem és elfogadom a 45/2014(II.26) Korm.r. 15. § szerint, hogy megrendelésem fizetési kötelezettséggel jár.<br/>
                        - Megismertem és elfogadom az{" "}
                        <a target="_blank" href={`${process.env.REACT_APP_ROOT_URL}/aszf`} rel="noopener">ÁSZF</a>
                        -et.<br/>
                        - Megismertem és elfogadom az{" "}
                        <a
                            href={`${process.env.REACT_APP_ROOT_URL}/adatkezeles`}
                            target="_blank"
                            rel="noopener"
                        >
                            Adatkezelési Tájékoztatót
                        </a>.
                    </IonLabel>
                </IonItem>
                <IonButton
                    expand="block"
                    onClick={handleOrderComplete}
                    disabled={
                        cartItemsByWeekLength === 0 ||
                        (needsShipping && selectedShippingLocation === null) ||
                        selectedPaymentMethod === null ||
                        aszf === false
                    }
                >
                    {selectedPaymentMethod === "cash" ? "Megrendelés" : "Fizetés"}
                </IonButton>
            </IonCardContent>
        </IonCard>
    );
};
export default PaymentMethodsCard;
