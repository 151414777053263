import React, { useEffect, useState } from "react";
import {
    IonLabel,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonItem,
} from "@ionic/react";

import { setRatingModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { useDispatch, useSelector } from "react-redux";

import { ModelMenuItem } from "../../../models/FoodModel";
import { addLoading, removeLoading } from "../../loading/Actions";
import {
    addApiErrors,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
    hasError,
    setInitError,
} from "../../../helpers/form/GlobalHelpers";
import useFormValidation from "../../../helpers/form/FormValidationHelper";
import { addToast } from "../../toast/Actions";
import { postRating, getRating } from "../../../services/FoodService";
import ValidateRate from "../../../helpers/validators/ValidateRate";
import ModalWithImage from "../../../components/modals/ModalWithImage";
import { checkmarkCircleOutline } from "ionicons/icons";
import InlineAlert from "../../../components/InlineAlert";
import { updateDetailsFood } from "../../cart/Actions";

const INIT_STATE = {
    rating: "",
    comment: "",
    food_id: "",
};

const RatingModal: React.FC = () => {
    const dispatch = useDispatch();

    const [modalStatus, setModalStatus] = useState<{
        show: boolean;
        menu_item: ModelMenuItem;
    }>({ show: false, menu_item: null });

    const ratingModalFromReducer = useSelector(
        (state: RootState) => state.RatingModalReducer
    );

    const { handleChange, values } = useFormValidation(INIT_STATE);
    const [status, setStatus] = useState({
        error_list: INIT_STATE,
    });

    const [alreadyRated, setAlreadyRated] = useState<number | null>(null);

    useEffect(() => {
        if (ratingModalFromReducer.menu_item) {
            handleGetRating();
        }

        handleChange({
            detail: {},
            target: {
                name: "food_id",
                value: ratingModalFromReducer.menu_item
                    ? ratingModalFromReducer.menu_item.food.id
                    : null,
            },
        });
        handleChange({
            detail: {},
            target: {
                name: "rating",
                value: "",
            },
        });
        handleChange({
            detail: {},
            target: {
                name: "comment",
                value: "",
            },
        });
        setModalStatus({
            show: ratingModalFromReducer.showModal,
            menu_item: ratingModalFromReducer.menu_item,
        });
        setStatus({
            error_list: INIT_STATE,
        });
    }, [ratingModalFromReducer]);

    const onCloseModal = () => {
        dispatch(setRatingModal(false, null));
    };

    const handleGetRating = async () => {
        dispatch(addLoading());
        getRating(ratingModalFromReducer.menu_item.food.id)
            .then((res: any) => {
                if (res !== undefined && res.data?.data?.my_rating !== null) {
                    setAlreadyRated(res.data.data.my_rating);
                    handleChange({
                        detail: {},
                        target: {
                            name: "rating",
                            value: parseInt(res.data.data.my_rating),
                        },
                    });
                } else {
                    setAlreadyRated(null);
                }
                dispatch(removeLoading());
            })
            .catch((err: any) => {
                setAlreadyRated(null);
                dispatch(removeLoading());
            });
    };

    const handleSendRating = async () => {
        dispatch(addLoading());
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateRate(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            postRating(values.food_id, values.rating, values.comment)
            .then((res: any) => {
                dispatch(removeLoading());
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt az értékelés elküldése közben...",
                        setStatus
                    )
                ) {
                    return;
                }
                dispatch(
                    addToast({
                        message: "Sikeres értékelés",
                        duration: 3000,
                        color: "success",
                    })
                );
                dispatch(setRatingModal(false, null));
                dispatch(updateDetailsFood(new Date()));
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt az értékelés elküldése közben...",
                    setStatus
                );
            });
        }
    };

    return (
        <ModalWithImage
            title={
                modalStatus.menu_item?.food.display_name ??
                modalStatus.menu_item?.food.name
            }
            subtitle={"Értékelje a kiválasztott ételet 1-től 5-ös skálán."}
            onCloseModal={onCloseModal}
            show={modalStatus.show}
            id="ratingModal"
        >
            <div className="form">
                <div>
                    {(alreadyRated !== null && alreadyRated !== undefined) && 
                        <>
                            <InlineAlert
                                icon={checkmarkCircleOutline}
                                text="Ön korábban már értékelte ezt az ételt."
                                color="success"
                            />
                            <br/><br/>
                        </>
                    }
                    <IonSegment
                        onIonChange={(e) =>
                            handleChange({
                                detail: {},
                                target: {
                                    name: "rating",
                                    value: parseInt(e.detail.value),
                                },
                            })
                        }
                        value={values.rating}
                    >
                        <IonSegmentButton value="1">
                            <IonLabel>1</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="2">
                            <IonLabel>2</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="3">
                            <IonLabel>3</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="4">
                            <IonLabel>4</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="5">
                            <IonLabel>5</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    <br />

                    <IonItem
                        lines="none"
                        className="rounded-input small-input"
                    >
                        <IonTextarea
                            name="comment"
                            placeholder="Írja le a véleményét az ételről..."
                            rows={5}
                            onIonChange={handleChange}
                        ></IonTextarea>
                    </IonItem>

                    <ErrorsList errors={status.error_list} />

                    <div className="ion-text-center">
                        <IonButton
                            id="ratingButton"
                            size="large"
                            expand="block"
                            onClick={handleSendRating}
                        >
                            Értékelés küldése
                        </IonButton>
                    </div>
                </div>
            </div>
        </ModalWithImage>
    );
};

export default RatingModal;
