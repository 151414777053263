import {
    IonCheckbox,
    IonInput,
    IonItem,
    IonLabel,
    IonText,
    IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    getServiceAreas,
    getServiceLocations,
} from "../../services/ShippingService";
import Select from "react-select";
import "./ShippingAddress.scss";
import SelectStyle from "../../helpers/SelectStyle";
import {
    handleApiError,
    handleApiResponseError,
} from "../../helpers/form/GlobalHelpers";
import { MaskedIonInput } from "../MaskedIonInput";

type Props = {
    values: {
        is_company: boolean;
        tax_number: string;
        clerk_name: string;
        shipping_name: string;
        service_location_id: string;
        service_location_not_found: boolean;
        service_location_comment: string;
        shipping_address: string;
        shipping_gate_phone: string;
        shipping_phone_number: string;
        shipping_comment: string;
        is_billing_equals_to_shipping: boolean;
        service_area_id: number;
    };
    handleChange: any;
    handleKeyDown: any;
    status: {
        error_list: {
            is_company: string;
            tax_number: string;
            shipping_name: string;
            clerk_name: string;
            service_location_id: string;
            service_location_not_found: string;
            service_location_comment: string;
            shipping_address: string;
            shipping_gate_phone: string;
            shipping_phone_number: string;
            shipping_comment: string;
            is_billing_equals_to_shipping: string;
            service_area_id: string;
        };
    };
    companyData: {
        isCompanySelect: string | null;
        changeIsCompanySelect: (isCompany: string | null) => void;
    };
};

const isCompanyOptions = [
    { value: "personal", label: "Magánszemély" },
    {
        value: "company",
        label: "Gazdasági társaság (cég) vagy magánvállalkozó",
    },
];

const ShippingAddress: React.FC<Props> = ({
    values,
    handleChange,
    handleKeyDown,
    status,
    companyData,
}) => {
    const dispatch = useDispatch();
    const [serviceAreas, setServiceAreas] = useState<any>([]);
    const [serviceLocations, setServiceLocations] = useState<any>([]);
    /*const [selectedServiceArea, setSelectedServiceArea] = useState(
        serviceAreaId
    );*/

    useEffect(() => {
        getServiceAreas()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kiszállítási területek lekérdezése közben..."
                    )
                ) {
                    return;
                }
                let tempServiceAreas: any = [];
                res.data.data.map((serviceArea: any) =>
                    tempServiceAreas.push({
                        value: serviceArea.id,
                        label:
                            serviceArea.postal_code +
                            " - " +
                            serviceArea.city_name,
                    })
                );
                setServiceAreas(tempServiceAreas);
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a kiszállítási területek lekérdezése közben..."
                );
            });
    }, []);

    useEffect(() => {
        if (values.service_area_id) {
            getServiceLocations(values.service_area_id)
                .then((res: any) => {
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a kiszállítási körzetek lekérdezése közben..."
                        )
                    ) {
                        return;
                    }
                    let tempServiceLocations: any = [];
                    res.data.data.map((serviceLocation: any) =>
                        tempServiceLocations.push({
                            value: serviceLocation.id,
                            label: serviceLocation.name,
                        })
                    );
                    setServiceLocations(tempServiceLocations);
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a kiszállítási körzetek lekérdezése közben..."
                    );
                });
        }
    }, [values.service_area_id]);

    const getSelectedServiceAreaOption = () => {
        if (
            !serviceAreas ||
            serviceAreas.length === 0 ||
            !values.service_area_id
        ) {
            return null;
        }

        return serviceAreas.find(
            (item: any) => item.value === values.service_area_id
        );
    };

    const getSelectedIsCompanyOption = () => {
        if (!companyData.isCompanySelect) {
            return null;
        }
        return isCompanyOptions.find(
            (item: any) => item.value.toString() === companyData.isCompanySelect
        );
    };

    const getSelectedServiceLocationOption = () => {
        if (
            !serviceLocations ||
            serviceLocations.length === 0 ||
            !values.service_location_id
        ) {
            return null;
        }
        return serviceLocations.find(
            (item: any) => item.value === values.service_location_id
        );
    };

    return (
        <>
            <Select
                options={isCompanyOptions}
                onChange={(value: any) => {
                    handleChange({
                        target: {
                            name: "is_company",
                            value: value?.value === "company" ?? "",
                        },
                        detail: {
                            checked: undefined,
                        },
                    });
                    companyData.changeIsCompanySelect(value?.value ?? null);
                }}
                onKeyDown={handleKeyDown}
                className="shipping-select"
                noOptionsMessage={() => "Nincs találat"}
                placeholder="Cég vagy magánszemély*"
                value={getSelectedIsCompanyOption()}
                styles={SelectStyle}
                isClearable={true}
            />

            {(companyData.isCompanySelect === "personal" ||
                companyData.isCompanySelect === "company") && (
                <>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                            status.error_list.clerk_name
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">
                            {companyData.isCompanySelect === "company"
                                ? "Ügyintéző neve*"
                                : "Név*"}
                        </IonLabel>
                        <IonInput
                            title={
                                companyData.isCompanySelect === "company"
                                    ? "Ügyintéző neve"
                                    : "Név"
                            }
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            name="clerk_name"
                            value={values.clerk_name}
                            required
                            autofocus={true}
                            data-testid="input-clerk_name"
                        ></IonInput>
                    </IonItem>
                    {companyData.isCompanySelect === "company" && (
                        <>
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.shipping_name
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">Cégnév*</IonLabel>
                                <IonInput
                                    title="Cégnév"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    name="shipping_name"
                                    value={values.shipping_name}
                                    required
                                    autofocus={true}
                                    data-testid="input-shipping_name"
                                ></IonInput>
                            </IonItem>
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.tax_number
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">Adószám*</IonLabel>
                                <MaskedIonInput
                                    value={values.tax_number}
                                    onChange={handleChange}
                                    name="tax_number"
                                />
                            </IonItem>
                        </>
                    )}

                    <Select
                        options={serviceAreas}
                        onChange={(value: any) => {
                            handleChange({
                                detail: {},
                                target: {
                                    name: "service_area_id",
                                    value: value?.value ?? null,
                                },
                            });
                        }}
                        onKeyDown={handleKeyDown}
                        className="shipping-select"
                        noOptionsMessage={() => "Nincs találat"}
                        placeholder="Szállítási körzet kiválasztása*"
                        value={getSelectedServiceAreaOption()}
                        styles={SelectStyle}
                        isClearable={true}
                    />

                    {values.service_area_id && (
                        <>
                            {!values.service_location_not_found && (
                                <Select
                                    options={serviceLocations}
                                    onChange={(value) =>
                                        handleChange({
                                            detail: {},
                                            target: {
                                                name: "service_location_id",
                                                value: value?.value ?? null,
                                            },
                                        })
                                    }
                                    onKeyDown={handleKeyDown}
                                    className="shipping-select"
                                    noOptionsMessage={() => "Nincs találat"}
                                    placeholder="Kiszállítási terület kiválasztása*"
                                    value={getSelectedServiceLocationOption()}
                                    styles={SelectStyle}
                                    isClearable={true}
                                />
                            )}
                            <IonItem
                                lines="none"
                                className={
                                    "checkbox-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.service_location_not_found
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonCheckbox
                                    onIonChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    name="service_location_not_found"
                                    checked={values.service_location_not_found}
                                    data-testid="input-service_location_not_found"
                                />
                                <IonLabel>Nem találom a listában</IonLabel>
                            </IonItem>

                            {values.service_location_not_found && (
                                <IonItem
                                    lines="none"
                                    className={
                                        "rounded-input small-input " +
                                        (Boolean(status.error_list) &&
                                        status.error_list
                                            .service_location_comment
                                            ? "error"
                                            : "")
                                    }
                                >
                                    <IonLabel position="floating">
                                        Hova kéri a szállítást
                                    </IonLabel>
                                    <IonInput
                                        title="Hova kéri a szállítást"
                                        onIonInput={handleChange}
                                        onKeyDown={handleKeyDown}
                                        clearOnEdit={false}
                                        autocomplete="address-line2"
                                        name="service_location_comment"
                                        value={values.service_location_comment}
                                        required
                                        data-testid="input-service_location_comment"
                                    ></IonInput>
                                </IonItem>
                            )}

                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.shipping_address
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">
                                    Házszám, emelet, ajtó*
                                </IonLabel>
                                <IonInput
                                    title="Házszám, emelet, ajtó"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    autocomplete="address-line1"
                                    name="shipping_address"
                                    value={values.shipping_address}
                                    required
                                    data-testid="input-shipping_address"
                                ></IonInput>
                            </IonItem>
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.shipping_gate_phone
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">
                                    Kaputelefon
                                </IonLabel>
                                <IonInput
                                    title="Kaputelefon"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    name="shipping_gate_phone"
                                    value={values.shipping_gate_phone}
                                    data-testid="input-shipping_gate_phone"
                                ></IonInput>
                            </IonItem>
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.shipping_phone_number
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">Telefon*</IonLabel>
                                <IonInput
                                    title="Telefon"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    autocomplete="tel"
                                    name="shipping_phone_number"
                                    value={values.shipping_phone_number}
                                    required
                                    data-testid="input-shipping_phone_number"
                                ></IonInput>
                            </IonItem>
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list.shipping_comment
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">
                                    Megjegyzés (max. 100 karakter)
                                </IonLabel>
                                <IonTextarea
                                    title="Megjegyzés (max. 100 karakter)"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    name="shipping_comment"
                                    value={values.shipping_comment}
                                    rows={3}
                                    maxlength={100}
                                    data-testid="input-shipping_comment"
                                ></IonTextarea>
                            </IonItem>
                            <IonItem
                                lines="none"
                                className={
                                    "checkbox-input " +
                                    (Boolean(status.error_list) &&
                                    status.error_list
                                        .is_billing_equals_to_shipping
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonCheckbox
                                    title="A számlázási cím megegyezik a szállítási címmel"
                                    onIonChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    name="is_billing_equals_to_shipping"
                                    checked={
                                        values.is_billing_equals_to_shipping
                                    }
                                    data-testid="input-is_billing_equals_to_shipping"
                                />
                                <IonLabel>
                                    A számlázási cím megegyezik a szállítási
                                    címmel
                                </IonLabel>
                            </IonItem>
                        </>
                    )}
                </>
            )}
            {/*<IonItem
                lines="none"
                className={
                    "checkbox-input " +
                    (Boolean(status.error_list) && status.error_list.is_company
                        ? "error"
                        : "")
                }
            >
                <IonCheckbox
                    title="Cégként regisztrál"
                    onIonChange={handleChange}
                    onKeyDown={handleKeyDown}
                    name="is_company"
                    checked={values.is_company}
                    data-testid="input-is_company"
                />
                <IonLabel>Cégként regisztrál</IonLabel>
            </IonItem>*/}
        </>
    );
};

export default ShippingAddress;
