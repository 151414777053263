import React from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonSpinner,
} from "@ionic/react";
import { basket } from "ionicons/icons";
import moment from "moment";
import NumberFormat from "react-number-format";
import { checkCoupon } from "../../services/ShippingService";
import { useDispatch } from "react-redux";
import {
    handleApiError,
    handleApiResponseError,
} from "../../helpers/form/GlobalHelpers";
import { addToast } from "../../store/toast/Actions";
import { SumCartItems } from "../../helpers/functions/CartHelper";
import { ModelCartItem } from "../../models/FoodModel";

interface Props {
    cartItemsByWeek: any[];
    selectedShippingLocation: number | null;
    cartPrices: {
        cartPricesLoaded: boolean;
        cartPrices: {
            totals: number;
            totals_with_discount: number;
            discount: number;
            coupon_value: number;
        };
    };
    validatedCoupon: string;
    coupon: string;
    handleCouponChange: (value: string) => void;
    handleValidatedCouponChange: (value: string) => void;
}

const OrderDetailsCard: React.FC<Props> = ({
    cartItemsByWeek,
    selectedShippingLocation,
    cartPrices,
    validatedCoupon,
    coupon,
    handleCouponChange,
    handleValidatedCouponChange,
}) => {
    const dispatch = useDispatch();

    const handleCheckCoupon = () => {
        checkCoupon(coupon, selectedShippingLocation)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kupon ellenőrzésekor, kérjük próbálja újra később..."
                    )
                ) {
                    handleValidatedCouponChange("");
                    return;
                }

                handleCouponChange("");
                handleValidatedCouponChange(coupon);
                dispatch(
                    addToast({
                        color: "success",
                        duration: 2000,
                        message: "Kupon beváltva",
                    })
                );
            })
            .catch((err: any) => {
                handleValidatedCouponChange("");
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a kupon ellenőrzésekor, kérjük próbálja újra később..."
                );
            });
    };

    const groupWeeklyMenuCartItems = (cartItems: ModelCartItem[]): any[] => {
        let groups = cartItems.reduce((groups: any, item: ModelCartItem) => {
            groups[item.category.name] = [
                ...(groups[item.category.name] || []),
                item,
            ];
            return groups;
        }, {});
        let cartElementsWeekly: any[] = [];
        for (const [key, value] of Object.entries(groups)) {
            let tempVal: any = value;
            cartElementsWeekly.push({
                category: key,
                code: tempVal[0].menu_item.category.code,
                price: SumCartItems(value as ModelCartItem[]),
            });
        }
        return cartElementsWeekly;
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>
                    <span className="checkoutBadge">
                        <IonIcon icon={basket} />
                    </span>
                    Rendelés részletei
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {selectedShippingLocation === null ? (
                    <div className="ion-text-center">
                        <p>
                            Válasszon szállítási címet a "Szállítás" dobozból.
                        </p>
                        <br />
                    </div>
                ) : (
                    <>
                        {cartItemsByWeek.length > 0 ? (
                            <>
                                {Object.values(cartItemsByWeek).map(
                                    (weekData: any, weekIndex: number) => (
                                        <div key={weekIndex}>
                                            <div className="leftRight bold">
                                                <span>
                                                    {weekData.week + ". hét "}
                                                </span>
                                                <span>
                                                    {moment()
                                                        .year(weekData.year)
                                                        .week(weekData.week)
                                                        .startOf("week")
                                                        .format("YYYY.MM.DD") +
                                                        " - " +
                                                        moment()
                                                            .year(weekData.year)
                                                            .week(weekData.week)
                                                            .startOf("week")
                                                            .add(6, "day")
                                                            .format(
                                                                "YYYY.MM.DD"
                                                            )}
                                                </span>
                                            </div>
                                            <div className="CartDaysHolder">
                                                {weekData.weeklyItems &&
                                                    weekData.weeklyItems
                                                        .length > 0 && (
                                                        <div>
                                                            {groupWeeklyMenuCartItems(
                                                                weekData.weeklyItems
                                                            ).map(
                                                                (
                                                                    weeklyItem: any,
                                                                    weeklyItemIndex: number
                                                                ) => (
                                                                    <div
                                                                        className="leftRight"
                                                                        key={
                                                                            weeklyItemIndex
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                weeklyItem.code
                                                                            }
                                                                            {" - "}
                                                                            {
                                                                                weeklyItem.category
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                weeklyItem.price
                                                                            }
                                                                            {
                                                                                " Ft"
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                {weekData.daysData
                                                    .filter(
                                                        (dayData: any) =>
                                                            dayData.dailyItems &&
                                                            dayData.dailyItems
                                                                .length > 0
                                                    )
                                                    .map(
                                                        (
                                                            dayData: any,
                                                            dayIndex: number
                                                        ) => (
                                                            <div key={dayIndex}>
                                                                <div className="leftRight">
                                                                    <span>
                                                                        {moment(
                                                                            dayData.date
                                                                        )
                                                                            .locale(
                                                                                "hu"
                                                                            )
                                                                            .format(
                                                                                "dddd"
                                                                            )}
                                                                    </span>
                                                                    <span>
                                                                        {SumCartItems(
                                                                            dayData.dailyItems
                                                                        )}{" "}
                                                                        Ft
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </>
                        ) : (
                            <div className="emptyCart">
                                <h4>Nincs tétel a kosarában.</h4>
                                <p>
                                    Nem adott még semmit a kosarához! Kezdjen el
                                    hozzáadni ételeket, ha szeretne rendelni.
                                </p>
                            </div>
                        )}
                        <hr />
                        {cartPrices.cartPricesLoaded ? (
                            <>
                                {cartPrices.cartPrices.coupon_value > 0 &&
                                    cartPrices.cartPrices.discount > 0 && (
                                        <div className="leftRight bold">
                                            <span>Összesen:</span>
                                            <span>
                                                <NumberFormat
                                                    isNumericString={true}
                                                    value={parseFloat(
                                                        cartPrices.cartPrices.totals.toString()
                                                    )}
                                                    displayType={"text"}
                                                    thousandSeparator={" "}
                                                    suffix={" Ft"}
                                                />
                                            </span>
                                        </div>
                                    )}
                                {cartPrices.cartPrices.discount > 0 && (
                                    <div className="leftRight bold">
                                        <span>Kedvezmény:</span>
                                        <span>
                                            {"-" +
                                                cartPrices.cartPrices.discount}
                                            %
                                        </span>
                                    </div>
                                )}
                                {cartPrices.cartPrices.coupon_value > 0 && (
                                    <div className="leftRight bold">
                                        <span>Kupon:</span>
                                        <span>
                                            {cartPrices.cartPrices.coupon_value}{" "}
                                            Ft
                                        </span>
                                    </div>
                                )}
                                <div className="leftRight bold">
                                    <span>Fizetendő:</span>
                                    <span>
                                        <NumberFormat
                                            isNumericString={true}
                                            value={parseFloat(
                                                cartPrices.cartPrices.totals_with_discount.toString()
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={" "}
                                            suffix={" Ft"}
                                        />
                                    </span>
                                </div>
                                <br />
                                {selectedShippingLocation !== null && (
                                    <div className="coupon-section">
                                        <IonItem
                                            lines="none"
                                            className="rounded-input small-input"
                                        >
                                            <IonLabel position="floating">
                                                Kupon
                                            </IonLabel>
                                            <IonInput
                                                title="Kupon"
                                                onIonInput={(event: any) =>
                                                    handleCouponChange(
                                                        event.target.value
                                                    )
                                                }
                                                clearOnEdit={false}
                                                type="text"
                                                name="coupon"
                                                value={
                                                    validatedCoupon !== ""
                                                        ? validatedCoupon
                                                        : coupon
                                                }
                                                readonly={
                                                    validatedCoupon !== ""
                                                }
                                                maxlength={64}
                                                data-testid="input-checkout-coupon"
                                            ></IonInput>
                                            {validatedCoupon ? (
                                                <IonButton
                                                    slot="end"
                                                    color="danger"
                                                    fill="solid"
                                                    onClick={() =>
                                                        handleValidatedCouponChange(
                                                            ""
                                                        )
                                                    }
                                                >
                                                    Törlés
                                                </IonButton>
                                            ) : (
                                                <IonButton
                                                    slot="end"
                                                    color="secondary"
                                                    fill="solid"
                                                    onClick={handleCheckCoupon}
                                                >
                                                    Beváltás
                                                </IonButton>
                                            )}
                                        </IonItem>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="ion-text-center">
                                <IonSpinner />
                            </div>
                        )}
                    </>
                )}
            </IonCardContent>
        </IonCard>
    );
};
export default OrderDetailsCard;
