import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonCol,
    IonRow,
    IonAlert,
} from "@ionic/react";
import moment from "moment";
import { useAuth } from "../context/AuthContext";

import { connect, useDispatch } from "react-redux";
import Header from "../layout/Header";

import { ModelCart, ModelCartItem } from "../models/FoodModel";
import { RootState } from "../store/RootReducer";
import { setLoginModal } from "../store/modals/login/Actions";
import { setRegistrationModal } from "../store/modals/registration/Actions";
import {
    ProcessCartItems,
    SumCartItems,
} from "../helpers/functions/CartHelper";
import {
    ModelPaymentMethod,
    ModelShippingLocation,
} from "../models/ShippingModel";
import {
    deleteShippingAddress,
    getOverlapOrder,
    getPaymentMethods,
    getShippingLocations,
    getTotal,
    postStartPayment,
    processShippingLocations,
} from "../services/ShippingService";

import { addLoading, removeLoading } from "../store/loading/Actions";
import { Redirect } from "react-router";
import { EMPTY_CART } from "../store/cart/ActionTypes";
import PageWrapper from "../layout/PageWrapper";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";
import MessageCenter from "../components/MessageCenter";
import { addToast } from "../store/toast/Actions";
import { loadCart } from "../store/cart/Reducer";
import { sendBarionPixel } from "../helpers/barion/PixelHelper";
import { getSuggestions } from "../services/CartService";
import useDebouncedEffect from "../helpers/DebouncedEffect";
import SuggestionsCard from "../components/checkout/SuggestionsCard";
import PaymentMethodsCard from "../components/checkout/PaymentMethodsCard";
import AddressCard from "../components/checkout/AddressCard";
import OrderDetailsCard from "../components/checkout/OrderDetailsCard";
import OrderDuplicateModal from "../components/OrderDuplicateModal";
import PersonsCard from "../components/checkout/PersonsCard";
import { ModelPerson } from "../models/Persons";
import { getPersons } from "../services/ProfileService";

interface StateFromReducer {
    cart: ModelCart;
}

type Props = StateFromReducer;

const CheckoutPage: React.FC<Props> = ({ cart }) => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();
    const [cartItemsByWeek, setCartItemsByWeek] = useState([]);
    const [suggestions, setSuggestions] = useState<
        Array<{ name: string; description: string; items: Array<any> }>
    >([]);
    const [aszf, setAszf] = useState<boolean>(false);
    const [showAddressDeleteConfirm, setShowAddressDeleteConfirm] = useState<{
        show: boolean;
        id: number | null;
    }>({ show: false, id: null });
    const [coupon, setCoupon] = useState<string>("");
    const [validatedCoupon, setValidatedCoupon] = useState<string>("");
    const [shippingLocations, setShippingLocations] = useState<{
        shippingLocationsLoaded: boolean;
        shippingLocations: Array<ModelShippingLocation>;
    }>({
        shippingLocationsLoaded: false,
        shippingLocations: [],
    });
    const [selectedShippingLocation, setSelectedShippingLocation] = useState<
        number | null
    >(null);
    const [persons, setPersons] = useState<{
        loaded: boolean;
        persons: ModelPerson[];
    }>({
        loaded: false,
        persons: [],
    });
    const [selectedPerson, setSelectedPerson] = useState<ModelPerson | null>(
        null
    );

    const fetchShippingLocations = (selectNewestAddress: boolean = false) => {
        getShippingLocations()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a címek lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setShippingLocations({
                        shippingLocationsLoaded: true,
                        shippingLocations: [],
                    });
                    return;
                }

                let tempShippingLocations = processShippingLocations(
                    res.data.data
                );
                setShippingLocations({
                    shippingLocationsLoaded: true,
                    shippingLocations: tempShippingLocations,
                });
                fetchPaymentMethods();
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a címek lekérdezése közben, kérjük próbálja újra később..."
                );
                setShippingLocations({
                    shippingLocationsLoaded: true,
                    shippingLocations: [],
                });
                fetchPaymentMethods();
            });
    };

    const fetchPersons = () => {
        getPersons()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a személyek lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setPersons({
                        loaded: true,
                        persons: [],
                    });
                    return;
                }
                setPersons({
                    loaded: true,
                    persons: res.data.data,
                });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a személyek lekérdezése közben, kérjük próbálja újra később..."
                );
                setPersons({
                    loaded: true,
                    persons: [],
                });
            });
    };

    const [paymentMethods, setPaymentMethods] = useState<{
        paymentMethodsLoaded: boolean;
        paymentMethods: Array<ModelPaymentMethod>;
    }>({
        paymentMethodsLoaded: false,
        paymentMethods: [],
    });
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState<string>("cash");

    useEffect(() => {
        sendBarionPixel("addPaymentInfo", {
            contents: cart.items.map((cartItem: ModelCartItem) => {
                return {
                    contentType: "Product",
                    currency: "HUF",
                    id: cartItem.menu_item.id.toString(),
                    name:
                        cartItem.menu_item?.food?.display_name ??
                        cartItem.menu_item.weekly_name,
                    quantity: cartItem.quantity,
                    totalItemPrice:
                        cartItem.quantity * cartItem.menu_item.price,
                    unit: "pcs.",
                    unitPrice: cartItem.menu_item.price,
                };
            }),
            paymentMethod: selectedPaymentMethod,
            step: "3",
        });
    }, [selectedPaymentMethod]);

    const [cartPrices, setCartPrices] = useState<{
        cartPricesLoaded: boolean;
        cartPrices: {
            totals: number;
            totals_with_discount: number;
            discount: number;
            coupon_value: number;
        };
    }>({
        cartPricesLoaded: false,
        cartPrices: {
            totals: 0,
            totals_with_discount: 0,
            discount: 0,
            coupon_value: 0,
        },
    });

    const fetchPaymentMethods = () => {
        if (selectedShippingLocation) {
            getPaymentMethods(selectedShippingLocation)
                .then((res: any) => {
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a fizetési módok lekérdezése közben, kérjük próbálja újra később..."
                        )
                    ) {
                        setPaymentMethods({
                            paymentMethodsLoaded: true,
                            paymentMethods: [],
                        });
                        return;
                    }

                    let tempPaymentMethods = new Array<ModelPaymentMethod>();
                    Object.values(res.data.data).map((method: any) => {
                        tempPaymentMethods.push({
                            name: method.name,
                            enabled: method.enabled,
                            message: method.message,
                        });
                    });
                    setPaymentMethods({
                        paymentMethodsLoaded: true,
                        paymentMethods: tempPaymentMethods,
                    });
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a fizetési módok lekérdezése közben, kérjük próbálja újra később..."
                    );
                    setPaymentMethods({
                        paymentMethodsLoaded: true,
                        paymentMethods: [],
                    });
                });
        }
    };

    const fetchTotal = () => {
        getTotal(selectedShippingLocation, validatedCoupon)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kosár lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setCartPrices({
                        cartPricesLoaded: true,
                        cartPrices: {
                            totals: 0,
                            totals_with_discount: 0,
                            discount: 0,
                            coupon_value: 0,
                        },
                    });
                    return;
                }

                setCartPrices({
                    cartPricesLoaded: true,
                    cartPrices: {
                        totals: res.data.data.totals,
                        totals_with_discount:
                            res.data.data.totals_with_discount,
                        discount: res.data.data.discount,
                        coupon_value: res.data.data.coupon_value,
                    },
                });
            })
            .catch((err: any) => {
                if (
                    err?.response?.data?.data &&
                    err.response.data.data.length > 0
                ) {
                    var tempRemovedItemsString = "";
                    err.response.data.data.forEach(
                        (removedItem: any) =>
                            (tempRemovedItemsString +=
                                removedItem.name +
                                " - " +
                                moment(removedItem.date).format("YYYY.MM.DD") +
                                "<br/>")
                    );

                    dispatch(loadCart());
                    dispatch(
                        addToast({
                            color: "danger",
                            duration: 120000,
                            message:
                                "A következő tételek eltávolításra kerültek a kosárból, mert már nem rendelhetők: <br/>" +
                                tempRemovedItemsString,
                        })
                    );
                } else {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a kosár lekérdezése közben, kérjük próbálja újra később..."
                    );
                }
                setCartPrices({
                    cartPricesLoaded: true,
                    cartPrices: {
                        totals: 0,
                        totals_with_discount: 0,
                        discount: 0,
                        coupon_value: 0,
                    },
                });
            });
    };

    useEffect(() => {
        refreshSuggestions();
        sendBarionPixel("initiateCheckout", {
            contents: cart.items.map((cartItem: ModelCartItem) => {
                return {
                    contentType: "Product",
                    currency: "HUF",
                    id: cartItem.menu_item.id.toString(),
                    name:
                        cartItem.menu_item?.food?.display_name ??
                        cartItem.menu_item.weekly_name,
                    quantity: cartItem.quantity,
                    totalItemPrice:
                        cartItem.quantity * cartItem.menu_item.price,
                    unit: "pcs.",
                    unitPrice: cartItem.menu_item.price,
                };
            }),
            currency: "HUF",
            revenue: SumCartItems(cart.items),
            step: "1",
            contentType: "Product",
        });
    }, []);

    const refreshSuggestions = () => {
        getSuggestions()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a javaslatok lekérdezésekor, kérjük próbálja újra később..."
                    )
                ) {
                    setSuggestions([]);
                    return;
                }
                let tempSuggestions: Array<{
                    name: string;
                    description: string;
                    items: Array<any>;
                }> = [];
                Object.values(res.data.data).map((item: any) =>
                    tempSuggestions.push(item)
                );
                setSuggestions(tempSuggestions);
            })
            .catch((err: any) => {
                setSuggestions([]);
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a javaslatok lekérdezésekor, kérjük próbálja újra később..."
                );
            });
    };

    useEffect(() => {
        if (
            authInfo.userInfo !== null &&
            authInfo.userInfo.email_verified_at !== null &&
            authInfo.userInfo.statement_accepted_at !== null
        ) {
            fetchShippingLocations();
            fetchPersons();
        }

        return () => {
            setShippingLocations({
                shippingLocationsLoaded: true,
                shippingLocations: [],
            });
            setSelectedShippingLocation(null);

            setPersons({
                loaded: true,
                persons: [],
            });
            setSelectedPerson(null);
        };
    }, [authInfo]);

    useEffect(() => {
        if (selectedShippingLocation !== null && authInfo.loggedIn) {
            fetchPaymentMethods();
            fetchTotal();
        } else {
            setPaymentMethods({
                paymentMethodsLoaded: true,
                paymentMethods: [],
            });
            setCartPrices({
                cartPricesLoaded: true,
                cartPrices: {
                    totals: 0,
                    totals_with_discount: 0,
                    discount: 0,
                    coupon_value: 0,
                },
            });
        }

        return () => {
            setPaymentMethods({
                paymentMethodsLoaded: false,
                paymentMethods: [],
            });
            setCartPrices({
                cartPricesLoaded: false,
                cartPrices: {
                    totals: 0,
                    totals_with_discount: 0,
                    discount: 0,
                    coupon_value: 0,
                },
            });
        };
    }, [validatedCoupon, selectedShippingLocation, authInfo, cart]);

    useDebouncedEffect(
        () => {
            refreshSuggestions();
        },
        1000,
        [cart]
    );

    useEffect(() => {
        if (cart.items.length > 0 && orderDuplicateChecked === false) {
            handleGetOverlapOrder();
        }

        return () => {
            setOrderDuplicateModal(null);
        };
    }, [cart]);

    useEffect(() => {
        if (cart.items.length > 0) {
            setCartItemsByWeek(ProcessCartItems(cart));
        }

        return () => {
            setCartItemsByWeek([]);
        };
    }, [cart]);

    const [orderDuplicateModal, setOrderDuplicateModal] = useState<
        number | null
    >(null);
    const [orderDuplicateChecked, setOrderDuplicateChecked] =
        useState<boolean>(false);

    const handleGetOverlapOrder = async () => {
        getOverlapOrder()
            .then((res: any) => {
                if (res.data.error === false) {
                    setOrderDuplicateModal(res.data.data);
                }
                setOrderDuplicateChecked(true);
            })
            .catch((err: any) => {
                console.log(err);
                setOrderDuplicateChecked(true);
            });
    };

    const [orderId, setOrderId] = useState<number | null>(null);
    const handleOrderComplete = async () => {
        dispatch(addLoading());

        postStartPayment(
            selectedShippingLocation,
            selectedPaymentMethod,
            process.env.REACT_APP_REDIRECT_URL,
            validatedCoupon,
            undefined,
            selectedPerson ? selectedPerson.id : undefined
        )
            .then((res: any) => {
                dispatch(removeLoading());
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a megrendelés leadásakor, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }

                sendBarionPixel("initiatePurchase", {
                    contents: cart.items.map((cartItem: ModelCartItem) => {
                        return {
                            contentType: "Product",
                            currency: "HUF",
                            id: cartItem.menu_item.id.toString(),
                            name:
                                cartItem.menu_item?.food?.display_name ??
                                cartItem.menu_item.weekly_name,
                            quantity: cartItem.quantity,
                            totalItemPrice:
                                cartItem.quantity * cartItem.menu_item.price,
                            unit: "pcs.",
                            unitPrice: cartItem.menu_item.price,
                        };
                    }),
                    currency: "HUF",
                    revenue: SumCartItems(cart.items),
                    step: "2",
                });

                dispatch({ type: EMPTY_CART });

                if (res.data.data.gateway_url !== null) {
                    window.location.href = res.data.data.gateway_url;
                } else {
                    setOrderId(res.data.data.order_data.id);
                }
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a megrendelés leadásakor, kérjük próbálja újra később..."
                );
                fetchTotal();
            });
    };

    const handleAddressDelete = (shippingLocationId: number) => {
        setShowAddressDeleteConfirm({ show: true, id: shippingLocationId });
    };

    const onOpenLoginModal = () => {
        dispatch(setLoginModal(true));
    };

    const onOpenRegModal = () => {
        dispatch(setRegistrationModal(true));
    };

    if (authInfo.loggedIn === null || authInfo.loggedIn === false) {
        onOpenLoginModal();
    }

    const handleCouponChange = (coupon: string) => {
        setCoupon(coupon);
    };

    const handleValidatedCouponChange = (coupon: string) => {
        setValidatedCoupon(coupon);
    };

    const handleAszfChange = () => {
        setAszf((prevState) => !prevState);
    };

    const handleSelectedPaymentMethodChange = (value: any) => {
        setSelectedPaymentMethod(value);
    };

    const handleSelectedShippingLocationChange = (value: number | null) => {
        setSelectedShippingLocation(value);
    };

    const handleSelectPerson = (value: ModelPerson) => {
        setSelectedPerson(value);
    };

    if (orderId !== null) {
        return <Redirect to={"/rendeles-informacio/" + orderId} />;
    }

    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                {authInfo.loggedIn ? (
                    <>
                        <IonGrid className="marginInner">
                            <IonRow>
                                <IonCol>
                                    <MessageCenter page="checkout" />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-text-left">
                                <IonCol size="12" sizeMd="8">
                                    <OrderDuplicateModal
                                        onClose={() =>
                                            setOrderDuplicateModal(null)
                                        }
                                        modelOpen={orderDuplicateModal !== null}
                                        orderId={orderDuplicateModal}
                                    />
                                    <AddressCard
                                        shippingLocations={shippingLocations}
                                        selectedShippingLocation={
                                            selectedShippingLocation
                                        }
                                        fetchShippingLocations={
                                            fetchShippingLocations
                                        }
                                        handleAddressDelete={
                                            handleAddressDelete
                                        }
                                        handleSelectedShippingLocationChange={
                                            handleSelectedShippingLocationChange
                                        }
                                    />
                                    {suggestions.length > 0 && (
                                        <>
                                            <br />
                                            <SuggestionsCard
                                                suggestions={suggestions}
                                            />
                                        </>
                                    )}
                                    <br />
                                    <PersonsCard
                                        persons={persons}
                                        selectedPerson={selectedPerson}
                                        handleSelectPerson={handleSelectPerson}
                                        refreshPersons={() => {
                                            fetchPersons();
                                        }}
                                    />
                                    <br />
                                    <PaymentMethodsCard
                                        selectedShippingLocation={
                                            selectedShippingLocation
                                        }
                                        paymentMethods={paymentMethods}
                                        selectedPaymentMethod={
                                            selectedPaymentMethod
                                        }
                                        cartItemsByWeek={cartItemsByWeek}
                                        aszf={aszf}
                                        cartItemsByWeekLength={
                                            cartItemsByWeek.length
                                        }
                                        handleAszfChange={handleAszfChange}
                                        handleOrderComplete={
                                            handleOrderComplete
                                        }
                                        handleSelectedPaymentMethodChange={
                                            handleSelectedPaymentMethodChange
                                        }
                                        needsShipping={true}
                                    />
                                </IonCol>
                                <IonCol
                                    size="12"
                                    sizeMd="4"
                                    className="orderDetails"
                                >
                                    <OrderDetailsCard
                                        cartItemsByWeek={cartItemsByWeek}
                                        selectedShippingLocation={
                                            selectedShippingLocation
                                        }
                                        cartPrices={cartPrices}
                                        validatedCoupon={validatedCoupon}
                                        coupon={coupon}
                                        handleCouponChange={handleCouponChange}
                                        handleValidatedCouponChange={
                                            handleValidatedCouponChange
                                        }
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                ) : (
                    <IonCard className="centerCard">
                        <IonCardHeader>
                            <IonCardTitle>Jelentkezzen be</IonCardTitle>
                            <IonCardSubtitle>
                                A rendelés befejezéséhez kérem regisztráljon
                                vagy jelentkezzen be!
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonButton
                                expand="block"
                                onClick={onOpenLoginModal}
                            >
                                Bejelentkezés
                            </IonButton>
                            <IonButton
                                expand="block"
                                color="medium"
                                onClick={onOpenRegModal}
                            >
                                Regiszráció
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                )}
            </PageWrapper>
            <IonAlert
                isOpen={showAddressDeleteConfirm.show}
                header={"Cím törlés"}
                message={"Biztosan törölni szeretné?"}
                buttons={[
                    {
                        text: "Mégse",
                        role: "cancel",
                        cssClass: "danger",
                        handler: () => {
                            setShowAddressDeleteConfirm({
                                show: false,
                                id: null,
                            });
                        },
                    },
                    {
                        text: "Törlés",
                        handler: () => {
                            deleteShippingAddress(showAddressDeleteConfirm.id)
                                .then((res) => {
                                    fetchShippingLocations();
                                    dispatch(
                                        addToast({
                                            color: "success",
                                            message: "Sikeres törlés",
                                            duration: 2000,
                                        })
                                    );
                                })
                                .catch((err) => {
                                    handleApiError(
                                        dispatch,
                                        err,
                                        "Hiba történt a cím törlése közben..."
                                    );
                                });
                            setShowAddressDeleteConfirm({
                                show: false,
                                id: null,
                            });
                        },
                    },
                ]}
            />
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        cart: state.CartReducer.cart,
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
