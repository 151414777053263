import React, { useState } from "react";
import { IonContent, IonMenu, IonSkeletonText, IonSpinner } from "@ionic/react";
import FoodDetailsContent from "./FoodDetailsContent";
import { menuController } from "@ionic/core";

import "./FoodDetailsMenu.scss";

const FoodDetailsMenu: React.FC = () => {
    const [enableSwipe, setEnableSwipe] = useState<boolean>(false);

    const [leftMenuOpen, setLeftMenuOpen] = useState<boolean>(false);
    menuController.isOpen("foodDetailsMenuLeft").then((result: boolean) => {
        setLeftMenuOpen(result);
    });

    const [rightMenuOpen, setRightMenuOpen] = useState<boolean>(false);
    menuController.isOpen("foodDetailsMenuRight").then((result: boolean) => {
        setRightMenuOpen(result);
    });

    return (
        <>
            <IonMenu
                contentId="FoodDetailsContent"
                side="start"
                menuId="foodDetailsMenuLeft"
                type="overlay"
                maxEdgeStart={150}
                swipeGesture={enableSwipe}
                onIonDidOpen={() => setEnableSwipe(true)}
                onIonDidClose={() => setEnableSwipe(false)}
            >
                {leftMenuOpen ?
                    <FoodDetailsContent />
                :
                    <IonContent id="FoodDetailsContent">
                        <div className="DetailsImage">
                            <IonSkeletonText />
                        </div>
                        <div className="DetailsContent">
                            <h2>
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%", height: "40px" }}
                                />
                            </h2>
                            <p>
                                <IonSkeletonText
                                    animated
                                    style={{ width: "100%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "40%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "70%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%", height: "16px" }}
                                />
                            </p>
                        </div>
                    </IonContent>
                }
            </IonMenu>
            <IonMenu
                contentId="FoodDetailsContent"
                side="end"
                menuId="foodDetailsMenuRight"
                type="overlay"
                maxEdgeStart={150}
                swipeGesture={enableSwipe}
                onIonDidOpen={() => setEnableSwipe(true)}
                onIonDidClose={() => setEnableSwipe(false)}
            >
                {rightMenuOpen ?
                    <FoodDetailsContent />
                :
                    <IonContent id="FoodDetailsContent">
                        <div className="DetailsImage">
                            <IonSkeletonText />
                        </div>
                        <div className="DetailsContent">
                            <h2>
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%", height: "40px" }}
                                />
                            </h2>
                            <p>
                                <IonSkeletonText
                                    animated
                                    style={{ width: "100%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "40%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "70%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%", height: "16px" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%", height: "16px" }}
                                />
                            </p>
                        </div>
                    </IonContent>
                }
            </IonMenu>
        </>
    );
};

export default FoodDetailsMenu;
