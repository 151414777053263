import moment from "moment";
import { ModelBanner } from "../../models/FoodModel";
import { getDays } from "../../services/FoodService";

export const fetchDates = async (year?: number | null, week?: number | null) => {
  let localYear = year;
  if (localYear === null || localYear === undefined) {
    localYear = moment().year();
  }

  let localWeek = week;
  if (localWeek === null || localWeek === undefined) {
    localWeek = moment().week();
  }

  return getDays(localYear, localWeek);
};

export const getBannerByCategory = (categoryId: number, banners: Array<ModelBanner>): ModelBanner | null => {
  const foundedBannerIndex = banners.findIndex((banner) => banner.category_id === categoryId);
  if (foundedBannerIndex !== -1) {
    return banners[foundedBannerIndex];
  }
  return null;
};

export const getImageLink = (menu_item_id: number, width: number, height: number) => {
  return process.env.REACT_APP_API_URL + "/v1/i?menu_item_id=" + menu_item_id + "&width=" + width + "&height=" + height;
};
