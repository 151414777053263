import React, { useState } from "react";
import {
    IonButton,
    IonLoading,
    IonInput,
    IonItem,
    IonLabel,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
} from "@ionic/react";
import { Redirect, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

import useFormValidation from "../helpers/form/FormValidationHelper";
import ValidatePasswordRecoveryNew from "../helpers/validators/ValidatePasswordRecovery";
import {
    setInitError,
    hasError,
    addApiErrors,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";

import { useDispatch } from "react-redux";
import { addLoading, removeLoading } from "../store/loading/Actions";
import { passwordRecovery } from "../services/UserService";
import Header from "../layout/Header";
import PageWrapper from "../layout/PageWrapper";

interface Props {
    onLogin: (login: boolean, token: string) => void;
}

const INIT_STATE = {
    password: "",
    password_confirmation: "",
};

const PasswordRecoveryPage: React.FC<Props> = ({ onLogin }) => {
    const dispatch = useDispatch();
    const { hash, email } = useParams<{ hash: string; email: string }>();
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { authInfo } = useAuth();
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === "Enter") {
            handleSendPasswordRecovery();
        }
    };

    const handleSendPasswordRecovery = async () => {
        dispatch(addLoading());
        setInitError(INIT_STATE, setStatus);

        const errors = ValidatePasswordRecoveryNew(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            passwordRecovery(
                unescape(email),
                hash,
                values.password,
                values.password_confirmation
            )
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a jelszó helyreállítása közben...",
                            setStatus
                        )
                    ) {
                        return;
                    }
                    onLogin(true, res.data.access_token);
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a jelszó helyreállítása közben...",
                        setStatus
                    );
                });
        }
    };

    if (authInfo.loggedIn !== null && authInfo.loggedIn === true) {
        return <Redirect to="/rendeles" />;
    }

    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                <IonCard className="centerCard">
                    <IonCardHeader>
                        <IonCardTitle>Új jelszó</IonCardTitle>
                        <IonCardSubtitle>
                            Az új jelszó igényléséhez kérem töltse ki az alábbi
                            két mezőt helyesen!
                        </IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div className="form">
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                        status.error_list.password
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">
                                    Új jelszó
                                </IonLabel>
                                <IonInput
                                    clearOnEdit={false}
                                    type="password"
                                    name="password"
                                    onIonChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    value={values.password}
                                    required
                                ></IonInput>
                            </IonItem>
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                        status.error_list.password_confirmation
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">
                                    Új jelszó mégegszer
                                </IonLabel>
                                <IonInput
                                    clearOnEdit={false}
                                    type="password"
                                    name="password_confirmation"
                                    onIonChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    value={values.password_confirmation}
                                    required
                                ></IonInput>
                            </IonItem>

                            <ErrorsList errors={status.error_list} />

                            <div className="ion-text-center">
                                <IonButton
                                    size="large"
                                    expand="block"
                                    onClick={handleSendPasswordRecovery}
                                >
                                    Jelszó megváltoztatása
                                </IonButton>
                            </div>
                        </div>
                    </IonCardContent>
                </IonCard>
            </PageWrapper>

            <IonLoading isOpen={status.loading} message={"Kérem várjon..."} />
        </div>
    );
};

export default PasswordRecoveryPage;
