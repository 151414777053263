import React, { useEffect, useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";

import { setAddressModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { addLoading, removeLoading } from "../../loading/Actions";
import useFormValidation from "../../../helpers/form/FormValidationHelper";
import {
    ValidateBillingAddress,
    ValidateShippingAddress,
} from "../../../helpers/validators/ValidateAddress";
import {
    addApiErrors,
    hasError,
    setInitError,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
} from "../../../helpers/form/GlobalHelpers";
import { useDispatch, useSelector } from "react-redux";
import ModalWithImage from "../../../components/modals/ModalWithImage";
import ShippingAddress from "../../../components/profile/ShippingAddress";
import BillingAddress from "../../../components/profile/BillingAddress";
import { saveShippingAddress } from "../../../services/ShippingService";
import { chevronBack, chevronForward } from "ionicons/icons";
import "./AddressModal.scss";
import { useAuth } from "../../../context/AuthContext";

const INIT_STATE = {
    is_company: "",
    tax_number: "",
    clerk_name: "",
    shipping_name: "",
    service_location_id: "",
    service_location_not_found: "",
    service_location_comment: "",
    shipping_address: "",
    shipping_gate_phone: "",
    shipping_phone_number: "",
    shipping_comment: "",
    shipping_location_id: "",
    is_billing_equals_to_shipping: "",
    billing_name: "",
    billing_postal_code: "",
    billing_city: "",
    billing_street: "",
    billing_address: "",
    service_area_id: "",
};
const INIT_VALUE = {
    is_company: false,
    tax_number: "",
    shipping_name: "",
    clerk_name: "",
    service_location_id: "",
    service_location_not_found: false,
    service_location_comment: "",
    shipping_address: "",
    shipping_gate_phone: "",
    shipping_phone_number: "",
    shipping_comment: "",
    shipping_location_id: "",
    is_billing_equals_to_shipping: true,
    billing_name: "",
    billing_postal_code: "",
    billing_city: "",
    billing_street: "",
    billing_address: "",
    service_area_id: "",
};

const AddressModal: React.FC = () => {
    const dispatch = useDispatch();
    const [modalStatus, setModalStatus] = useState<{
        show: boolean;
        onSave: () => void;
        //service_area_id: number;
    }>({ show: false, onSave: null });
    const [currentView, setCurrentView] = useState<number>(0);
    const [isCompanySelect, setIsCompanySelect] = useState<any>(null);
    const addressModalFromReducer = useSelector(
        (state: RootState) => state.AddressModalReducer
    );
    const { authInfo } = useAuth();

    let {
        values,
        handleChange,
        handleDirectChangeProperty,
        handleDirectChangeAll,
    } = useFormValidation(INIT_VALUE);

    useEffect(() => {
        setModalStatus({
            show: addressModalFromReducer.showModal,
            onSave: addressModalFromReducer.onSave,
        });

        if (addressModalFromReducer.editAddress) {
            let tempEditAddress: any = {
                ...addressModalFromReducer.editAddress,
            };

            tempEditAddress.shipping_location_id =
                addressModalFromReducer.editAddress.id;
            tempEditAddress.service_area_id = addressModalFromReducer
                .editAddress.service_location
                ? addressModalFromReducer.editAddress.service_location
                      .service_area_id
                : null;

            handleDirectChangeAll(tempEditAddress);
            setIsCompanySelect(
                tempEditAddress.is_company ? "company" : "personal"
            );
        } else {
            if (authInfo && authInfo.loggedIn) {
                handleDirectChangeProperty("clerk_name", authInfo.userInfo.name);
            }
        }
    }, [addressModalFromReducer]);

    const [status, setStatus] = useState({
        error_list: INIT_STATE,
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === "Enter") {
            handleSave();
        }
    };

    const handleSave = async () => {
        dispatch(addLoading());

        setInitError(INIT_STATE, setStatus);
        const errors =
            currentView === 0
                ? ValidateShippingAddress(values)
                : ValidateBillingAddress(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            if (currentView === 0 && !values.is_billing_equals_to_shipping) {
                setCurrentView(1);
                dispatch(removeLoading());
            } else {
                saveShippingAddress(values)
                    .then((res: any) => {
                        dispatch(removeLoading());
                        if (
                            handleApiResponseError(
                                dispatch,
                                res,
                                "Hiba történt a cím mentése közben...",
                                setStatus
                            )
                        ) {
                            return;
                        }
                        modalStatus.onSave();
                        dispatch(setAddressModal(false, null, null));
                    })
                    .catch((err: any) => {
                        handleApiError(
                            dispatch,
                            err,
                            "Hiba történt a cím mentése közben...",
                            setStatus
                        );
                    });
            }
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const onCloseModal = () => {
        dispatch(setAddressModal(false, null, null));
        setCurrentView(0);
        setIsCompanySelect(null);
        handleDirectChangeAll(INIT_VALUE);
        setStatus({
            error_list: INIT_STATE,
        });
    };

    return (
        <ModalWithImage
            title={
                addressModalFromReducer.editAddress !== null
                    ? "Cím szerkesztése"
                    : currentView !== 0
                    ? "Számlázási adatok"
                    : "Új szállítási cím"
            }
            onCloseModal={onCloseModal}
            show={modalStatus.show}
            id="addressModal"
            overflow={true}
        >
            <div className="form-wrapper">
                <form className="form" onSubmit={handleSubmit}>
                    {currentView === 0 ? (
                        <ShippingAddress
                            values={values}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                            status={status}
                            companyData={{
                                isCompanySelect: isCompanySelect,
                                changeIsCompanySelect: setIsCompanySelect,
                            }}
                        />
                    ) : (
                        <BillingAddress
                            values={values}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                            status={status}
                        />
                    )}
                    <ErrorsList errors={status.error_list} />

                    <div className="ion-text-center">
                        {currentView === 1 && (
                            <IonButton
                                id="backButton"
                                size="large"
                                expand="block"
                                onClick={() => setCurrentView(0)}
                                color="medium"
                            >
                                <IonIcon icon={chevronBack} />
                                Vissza a szállítási címhez
                            </IonButton>
                        )}
                        <IonButton
                            id="saveButton"
                            size="large"
                            expand="block"
                            onClick={handleSave}
                        >
                            {currentView === 0 &&
                            !values.is_billing_equals_to_shipping
                                ? "Számlázási adatok"
                                : values.shipping_location_id
                                ? "Mentés"
                                : "Létrehozás"}
                            {currentView === 0 &&
                                !values.is_billing_equals_to_shipping && (
                                    <IonIcon icon={chevronForward}></IonIcon>
                                )}
                        </IonButton>
                    </div>
                </form>
            </div>
        </ModalWithImage>
    );
};

export default AddressModal;
