import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { RootState } from "../store/RootReducer";
import { connect } from "react-redux";
import { useWeeks } from "../context/WeeksContext";
import { Link } from "react-router-dom";

import Dropdown, {
    DropdownTrigger,
    DropdownContent,
} from "react-simple-dropdown";

import moment from "moment";
import "moment/locale/hu";

import {
    IonButton,
    IonChip,
    IonIcon,
    IonLabel,
    IonRange,
    IonSkeletonText,
    IonSpinner,
} from "@ionic/react";
import {
    add,
    bookmark,
    checkmarkCircle,
    chevronBackOutline,
    chevronDownOutline,
    chevronForwardOutline,
    closeCircle,
    ellipseOutline,
    filter,
    heartCircle,
    heartDislikeCircle,
    notificationsCircle,
    trash,
} from "ionicons/icons";

import { DEFAULT_CALORIE, DEFAULT_CARB, ModelCalorie, ModelCartItem, ModelFilter } from "../models/FoodModel";

import NumberFormat from "react-number-format";
import { getFilters, processFilters } from "../services/FoodService";
import { useAuth } from "../context/AuthContext";
import { setLoginModal } from "../store/modals/login/Actions";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";

import "./Header.scss";
import {
    clearSavedFilters,
    getSavedFilters,
    saveFilters,
} from "../helpers/functions/FilterHelper";

interface Props {
    cartItems: Array<ModelCartItem>;
    dates?: Array<{ date: Date; shipping_mode: string }>;
    currentYear?: number;
    currentWeek?: number;
    filters: {
        filtersLoaded: boolean;
        filters: {
            labels: Array<ModelFilter>;
            ingredients: Array<ModelFilter>;
            favouritesOnly: boolean;
            lastMinutesOnly: boolean;
            calorie: ModelCalorie;
            carb: ModelCalorie;
        };
        filtersApplied: boolean;
    };
    setFilters: React.Dispatch<
        React.SetStateAction<{
            filtersLoaded: boolean;
            filters: {
                labels: Array<ModelFilter>;
                ingredients: Array<ModelFilter>;
                favouritesOnly: boolean;
                lastMinutesOnly: boolean;
                calorie: ModelCalorie;
                carb: ModelCalorie;
            };
            filtersApplied: boolean;
        }>
    >;
    swipeLeft: () => void;
    swipeRight: () => void;
    type?: string;
}

const HeaderBottom: React.FC<Props> = ({
    cartItems,
    dates,
    currentYear,
    currentWeek,
    filters,
    setFilters,
    swipeLeft,
    swipeRight,
    type,
}) => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();
    const { weeks } = useWeeks();
    const [savedFilters, setSavedFilters] = useState<{
        ingredients: Array<{
            id: number;
            enabled: "enabled" | "disabled" | "default";
        }>;
        labels: Array<{
            id: number;
            enabled: "enabled" | "disabled" | "default";
        }>;
    }>(getSavedFilters());

    function getPrice(date: Date): number {
        const cartItemsByDate = cartItems.filter((cartItem) =>
            moment(cartItem.date).isSame(date, "day")
        );
        const summary = cartItemsByDate.reduce((sumValue, cartItem) => {
            return (
                sumValue +
                (!cartItem.category.weekly_menu_of_category_id
                    ? cartItem.menu_item.price * cartItem.quantity
                    : 0)
            );
        }, 0);
        return summary;
    }

    const getFakeDays = () => {
        let fakeArr = [];
        for (let index = 0; index < 6; index++) {
            fakeArr.push(
                <div className="Day" key={index} data-index={index}>
                    <div className="Date">
                        <IonSkeletonText
                            animated
                            style={{ width: "40px", height: "14px" }}
                        />
                        <small>
                            <IonSkeletonText
                                animated
                                style={{ width: "60px", height: "10px" }}
                            />
                        </small>
                    </div>
                    <div className="Price">
                        <span>
                            <IonSkeletonText
                                animated
                                style={{ width: "40px", height: "16px" }}
                            />
                        </span>
                    </div>
                </div>
            );
        }

        return fakeArr;
    };

    const fetchFilters = () => {
        getFilters()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a szűrők lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setFilters({
                        filtersLoaded: true,
                        filters: {
                            labels: [],
                            ingredients: [],
                            favouritesOnly: false,
                            lastMinutesOnly: false,
                            calorie: DEFAULT_CALORIE,
                            carb: DEFAULT_CARB,
                        },
                        filtersApplied: filters.filtersApplied,
                    });
                    return;
                }

                setFilters({
                    filtersLoaded: true,
                    filters: processFilters(res, type),
                    filtersApplied: false,
                });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a szűrők lekérdezése közben, kérjük próbálja újra később..."
                );
                setFilters({
                    filtersLoaded: true,
                    filters: {
                        labels: [],
                        ingredients: [],
                        favouritesOnly: false,
                        lastMinutesOnly: false,
                        calorie: DEFAULT_CALORIE,
                        carb: DEFAULT_CARB,
                    },
                    filtersApplied: false,
                });
            });
    };

    const getShippingString = (shipping: string, date: Date) => {
        switch (shipping) {
            case "off":
                return "Szünnap";
            case "prev_day":
            default:
                var prevDay = moment(date).subtract(1, "days");
                return prevDay.format("dddd") + "i szállítás";
        }
    };

    const handleLabelClick = (
        type: string,
        label?: ModelFilter,
        key?: number
    ) => {
        if (type === "favouritesOnly") {
            if (!authInfo.loggedIn) {
                dispatch(setLoginModal(true));
                return;
            }

            setFilters((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    favouritesOnly: !prevState.filters.favouritesOnly,
                },
            }));
        } else if (type === "lastMinutesOnly") {
            setFilters((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    lastMinutesOnly: !prevState.filters.lastMinutesOnly,
                },
            }));
        } else if (type === "ingredients") {
            let items = [...filters.filters.ingredients];
            let item = { ...items[key] };

            if (item.enabled === "enabled") {
                item.enabled = "disabled";
            } else if (item.enabled === "disabled") {
                item.enabled = "default";
            } else {
                item.enabled = "enabled";
            }

            items[key] = item;
            setFilters((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    ingredients: items,
                },
            }));
        } else {
            let items = [...filters.filters.labels];
            let item = { ...items[key] };

            if (item.enabled === "enabled") {
                item.enabled = "disabled";
            } else if (item.enabled === "disabled") {
                item.enabled = "default";
            } else {
                item.enabled = "enabled";
            }

            items[key] = item;
            setFilters((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    labels: items,
                },
            }));
        }
    };

    const getFilterIcon = (filter: ModelFilter) => {
        switch (filter.enabled) {
            case "enabled":
                return <IonIcon icon={checkmarkCircle} />;
            case "disabled":
                return <IonIcon icon={closeCircle} />;
            case "default":
            default:
                return <IonIcon icon={ellipseOutline} />;
        }
    };

    const getFilterColor = (filter: ModelFilter) => {
        switch (filter.enabled) {
            case "enabled":
                return "success";
            case "disabled":
                return "danger";
            case "default":
            default:
                return "medium";
        }
    };

    useEffect(() => {
        fetchFilters();

        return () => {
            setFilters({
                filtersLoaded: false,
                filters: {
                    labels: [],
                    ingredients: [],
                    favouritesOnly: false,
                    lastMinutesOnly: false,
                    calorie: DEFAULT_CALORIE,
                    carb: DEFAULT_CARB,
                },
                filtersApplied: false,
            });
        };
    }, [type]);

    useEffect(() => {
        if (
            filters.filtersLoaded &&
            (filters.filters.ingredients.length > 0 ||
                filters.filters.labels.length > 0) &&
            !filters.filtersApplied
        ) {
            let tempFilterIngredients: Array<ModelFilter> = [];
            filters.filters.ingredients.map((filter) => {
                const foundSavedFilter = savedFilters.ingredients.find(
                    (savedFilterItem) => savedFilterItem.id === filter.id
                );
                let tempFilter = { ...filter };
                if (foundSavedFilter) {
                    tempFilter.enabled = foundSavedFilter.enabled;
                }
                tempFilterIngredients.push(tempFilter);
            });
            let tempFilterLabels: Array<ModelFilter> = [];
            filters.filters.labels.map((filter) => {
                const foundSavedFilter = savedFilters.labels.find(
                    (savedFilterItem) => savedFilterItem.id === filter.id
                );
                let tempFilter = { ...filter };
                if (foundSavedFilter) {
                    tempFilter.enabled = foundSavedFilter.enabled;
                }
                tempFilterLabels.push(tempFilter);
            });
            setFilters({
                filtersLoaded: filters.filtersLoaded,
                filtersApplied: true,
                filters: {
                    labels: tempFilterLabels,
                    ingredients: tempFilterIngredients,
                    favouritesOnly: filters.filters.favouritesOnly,
                    lastMinutesOnly: filters.filters.lastMinutesOnly,
                    calorie: filters.filters.calorie,
                    carb: filters.filters.carb,
                },
            });
        }
    }, [filters]);

    const handleSaveFilters = () => {
        const savedFilters = saveFilters(
            filters.filters.ingredients,
            filters.filters.labels
        );
        setSavedFilters({
            ingredients: savedFilters.ingredients,
            labels: savedFilters.labels,
        });
    };
    const handleDeleteFilters = () => {
        clearSavedFilters();
        setSavedFilters({ ingredients: [], labels: [] });
    };
    const handleDefaultFilters = (type: string) => {
        let tempIngredientFilters: Array<ModelFilter> = [];
        let tempLabelFilters: Array<ModelFilter> = [];
        if (type === "ingredient") {
            filters.filters.ingredients.map((item) => {
                let tempItem = { ...item };
                tempItem.enabled = "default";
                tempIngredientFilters.push(tempItem);
            });
        } else if (type === "label") {
            filters.filters.labels.map((item) => {
                let tempItem = { ...item };
                tempItem.enabled = "default";
                tempLabelFilters.push(tempItem);
            });

        }

        setFilters({
            filtersLoaded: filters.filtersLoaded,
            filtersApplied: filters.filtersApplied,
            filters: {
                labels:
                    type === "label"
                        ? tempLabelFilters
                        : [...filters.filters.labels],
                ingredients:
                    type === "ingredient"
                        ? tempIngredientFilters
                        : [...filters.filters.ingredients],
                favouritesOnly: type === "label" ? false : filters.filters.favouritesOnly,
                lastMinutesOnly: type === "label" ? false : filters.filters.lastMinutesOnly,
                calorie: type === "calorie" ? DEFAULT_CALORIE : filters.filters.calorie,
                carb: type === "carb" ? DEFAULT_CARB : filters.filters.carb,
            },
        });
    };

    const dropDownRef = useRef<any>();
    const dropDownFilterRef = useRef<any>();

    return (
        <div id="HeaderBottom" className={dates === undefined ? "noDates" : ""}>
            <div className="OldMenuWrapper">
                <div className="Container OldMenuRow">
                    {dates !== undefined && (
                        <Dropdown ref={dropDownRef}>
                            <DropdownTrigger className="filter-button">
                                <span>Kiválasztott hét:</span>
                                <div>
                                    <b>{currentWeek ?? " - "}. hét</b>
                                    <IonIcon icon={chevronDownOutline} />
                                </div>
                            </DropdownTrigger>
                            <DropdownContent>
                                <div className="weeks">
                                    {weeks.map(
                                        (week: any, weekIndex: number) => (
                                            <React.Fragment key={weekIndex}>
                                                {week.disabled ?
                                                    <a href="#" className="week-button disabled">
                                                        <span className="week">
                                                            {week.week}. hét
                                                        </span>
                                                        <span className="dates">
                                                            FELTÖLTÉS ALATT
                                                        </span>
                                                    </a>
                                                :
                                                    <Link
                                                        onClick={() =>
                                                            dropDownRef.current.hide()
                                                        }
                                                        className={
                                                            "week-button" +
                                                            (currentYear?.toString() ===
                                                                week.year.toString() &&
                                                            currentWeek?.toString() ===
                                                                week.week.toString()
                                                                ? " active"
                                                                : "")
                                                        }
                                                        key={weekIndex}
                                                        to={
                                                            "/rendeles/" +
                                                            week.year +
                                                            "/" +
                                                            week.week
                                                        }
                                                    >
                                                        <span className="week">
                                                            {week.week}. hét
                                                        </span>
                                                        <span className="dates">
                                                            {moment()
                                                                .day(1)
                                                                .week(week.week)
                                                                .format(
                                                                    "YYYY.MM.DD"
                                                                )}{" "}
                                                            -{" "}
                                                            {moment()
                                                                .day(6)
                                                                .week(week.week)
                                                                .format("YYYY.MM.DD")}
                                                        </span>
                                                    </Link>
                                                }
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            </DropdownContent>
                        </Dropdown>
                    )}
                    <Link
                        to="/last-minute/"
                        className="last-minute"
                    >
                        Last Minute
                    </Link>
                    <a
                        href={`${process.env.REACT_APP_ROOT_URL}/tudnivalok`}
                        target="_blank"
                        rel="noopener"
                    >
                        Tudnivalók
                    </a>
                    <a
                        href={`${process.env.REACT_APP_ROOT_URL}/szallitasi-teruletek`}
                        target="_blank"
                        rel="noopener"
                    >
                        Szállítási Területek
                    </a>
                    <a
                        href={`${process.env.REACT_APP_ROOT_URL}/gyik`}
                        target="_blank"
                        rel="noopener"
                    >
                        GYIK
                    </a>
                    <a
                        href={`${process.env.REACT_APP_ROOT_URL}/kapcsolat`}
                        target="_blank"
                        rel="noopener"
                    >
                        Kapcsolat
                    </a>
                    <a
                        href={`${process.env.REACT_APP_ROOT_URL}/blog`}
                        target="_blank"
                        rel="noopener"
                    >
                        Blog
                    </a>
                </div>
            </div>
            {dates !== undefined && (
                <div className="DaysRowWrapper">
                    <div className="Container DaysRow">
                        <div>
                            <Dropdown
                                ref={dropDownFilterRef}
                                disabled={filters.filtersLoaded === false}
                            >
                                <DropdownTrigger className="filter-button">
                                    <span>
                                        {(savedFilters.ingredients.length > 0 ||
                                            savedFilters.labels.length > 0) && (
                                            <IonIcon icon={bookmark} />
                                        )}
                                        Szűrés
                                    </span>
                                    <div>
                                        <IonIcon icon={chevronDownOutline} />
                                    </div>
                                </DropdownTrigger>
                                <DropdownContent>
                                    <div className="filters">
                                        {filters.filtersLoaded ? (
                                            <>
                                                <div className="filters-template-buttons">
                                                    <IonButton
                                                        color="success"
                                                        onClick={
                                                            handleSaveFilters
                                                        }
                                                    >
                                                        Szűrés mentése
                                                    </IonButton>
                                                    {(savedFilters.ingredients
                                                        .length > 0 ||
                                                        savedFilters.labels
                                                            .length > 0) && (
                                                        <IonButton
                                                            onClick={
                                                                handleDeleteFilters
                                                            }
                                                        >
                                                            Mentett szűrés
                                                            törlés
                                                        </IonButton>
                                                    )}
                                                </div>
                                                <h4>
                                                    Étel szűrő&nbsp;&nbsp;&nbsp;
                                                    <span
                                                        className="filters-default"
                                                        onClick={() =>
                                                            handleDefaultFilters("label")
                                                        }
                                                    >
                                                        Alaphelyzet
                                                    </span>
                                                </h4>
                                                <div className="filter-group">
                                                    <IonChip
                                                        outline={true}
                                                        color={
                                                            filters.filters
                                                                .favouritesOnly
                                                                ? "success"
                                                                : "medium"
                                                        }
                                                        onClick={() =>
                                                            handleLabelClick(
                                                                "favouritesOnly"
                                                            )
                                                        }
                                                    >
                                                        {filters.filters
                                                            .favouritesOnly ? (
                                                            <IonIcon
                                                                icon={
                                                                    heartCircle
                                                                }
                                                            />
                                                        ) : (
                                                            <IonIcon
                                                                icon={
                                                                    heartDislikeCircle
                                                                }
                                                            />
                                                        )}
                                                        <IonLabel>
                                                            Kedvencek
                                                        </IonLabel>
                                                    </IonChip>
                                                    <IonChip
                                                        outline={true}
                                                        color={
                                                            filters.filters
                                                                .lastMinutesOnly
                                                                ? "success"
                                                                : "medium"
                                                        }
                                                        onClick={() =>
                                                            handleLabelClick(
                                                                "lastMinutesOnly"
                                                            )
                                                        }
                                                    >
                                                        <IonIcon
                                                            icon={
                                                                notificationsCircle
                                                            }
                                                        />
                                                        <IonLabel>
                                                            Last Minute
                                                        </IonLabel>
                                                    </IonChip>

                                                    {filters.filters.labels.map(
                                                        (
                                                            label: any,
                                                            key: number
                                                        ) => (
                                                            <IonChip
                                                                outline={true}
                                                                color={getFilterColor(
                                                                    label
                                                                )}
                                                                key={label.id}
                                                                onClick={() =>
                                                                    handleLabelClick(
                                                                        "labels",
                                                                        label,
                                                                        key
                                                                    )
                                                                }
                                                            >
                                                                {getFilterIcon(
                                                                    label
                                                                )}
                                                                <IonLabel>
                                                                    {label.name ??
                                                                        ""}
                                                                </IonLabel>
                                                            </IonChip>
                                                        )
                                                    )}
                                                </div>
                                                <br />
                                                <h4>
                                                    Összetevő szűrő&nbsp;&nbsp;&nbsp;
                                                    <span
                                                        className="filters-default"
                                                        onClick={() =>
                                                            handleDefaultFilters("ingredient")
                                                        }
                                                    >
                                                        Alaphelyzet
                                                    </span>
                                                </h4>
                                                <div className="filter-group">
                                                    {filters.filters.ingredients.map(
                                                        (
                                                            ingredient: any,
                                                            key: number
                                                        ) => (
                                                            <IonChip
                                                                outline={true}
                                                                color={getFilterColor(
                                                                    ingredient
                                                                )}
                                                                key={
                                                                    ingredient.id
                                                                }
                                                                onClick={() =>
                                                                    handleLabelClick(
                                                                        "ingredients",
                                                                        ingredient,
                                                                        key
                                                                    )
                                                                }
                                                            >
                                                                {getFilterIcon(
                                                                    ingredient
                                                                )}
                                                                <IonLabel>
                                                                    {ingredient.name ??
                                                                        ""}
                                                                </IonLabel>
                                                            </IonChip>
                                                        )
                                                    )}
                                                </div>
                                                <br />
                                                <div className="filters-grid">
                                                    <div>
                                                        <h4>
                                                            Kalória&nbsp;&nbsp;&nbsp;
                                                            <span
                                                                className="filters-default"
                                                                onClick={() =>
                                                                    handleDefaultFilters("calorie")
                                                                }
                                                            >
                                                                Alaphelyzet
                                                            </span>
                                                        </h4>
                                                        <div className="filter-group">
                                                            <IonRange 
                                                                dualKnobs
                                                                pin
                                                                ticks
                                                                snaps
                                                                color="primary"
                                                                min={DEFAULT_CALORIE.lower}
                                                                max={DEFAULT_CALORIE.upper}
                                                                step={50}
                                                                value={filters.filters.calorie}
                                                                onIonChange={(e: any) => {
                                                                    if (e.detail.value.lower !== filters.filters.calorie.lower ||
                                                                        e.detail.value.upper !== filters.filters.calorie.upper) {
                                                                            setFilters((prevState) => ({
                                                                                ...prevState,
                                                                                filters: {
                                                                                    ...prevState.filters,
                                                                                    calorie: e.detail.value as any,
                                                                                },
                                                                            }));
                                                                    }
                                                                }}
                                                                debounce={500}
                                                            >
                                                                <IonLabel slot="start">{filters.filters.calorie.lower??""}</IonLabel>
                                                                <IonLabel slot="end">
                                                                    {filters.filters.calorie.upper ? 
                                                                        <>
                                                                            {filters.filters.calorie.upper === 2000 ?
                                                                                "2000+"
                                                                            :
                                                                                filters.filters.calorie.upper
                                                                            }
                                                                        </>
                                                                    :
                                                                        ""
                                                                    }
                                                                </IonLabel>
                                                            </IonRange>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h4>
                                                            Szénhidrát&nbsp;&nbsp;&nbsp;
                                                            <span
                                                                className="filters-default"
                                                                onClick={() =>
                                                                    handleDefaultFilters("carb")
                                                                }
                                                            >
                                                                Alaphelyzet
                                                            </span>
                                                        </h4>
                                                        <div className="filter-group">
                                                            <IonRange 
                                                                dualKnobs
                                                                pin
                                                                ticks
                                                                snaps
                                                                color="primary"
                                                                min={DEFAULT_CARB.lower}
                                                                max={DEFAULT_CARB.upper}
                                                                step={10}
                                                                value={filters.filters.carb}
                                                                onIonChange={(e: any) => {
                                                                    if (e.detail.value.lower !== filters.filters.carb.lower ||
                                                                        e.detail.value.upper !== filters.filters.carb.upper) {
                                                                            setFilters((prevState) => ({
                                                                                ...prevState,
                                                                                filters: {
                                                                                    ...prevState.filters,
                                                                                    carb: e.detail.value as any,
                                                                                },
                                                                            }));
                                                                    }
                                                                }}
                                                                debounce={500}
                                                            >
                                                                <IonLabel slot="start">{filters.filters.carb.lower??""}</IonLabel>
                                                                <IonLabel slot="end">
                                                                    {filters.filters.carb.upper ? 
                                                                        <>
                                                                            {filters.filters.carb.upper === 200 ?
                                                                                "200+"
                                                                            :
                                                                                filters.filters.carb.upper
                                                                            }
                                                                        </>
                                                                    :
                                                                        ""
                                                                    }
                                                                </IonLabel>
                                                            </IonRange>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="ion-padding ion-text-center">
                                                <IonSpinner />
                                            </div>
                                        )}
                                    </div>
                                </DropdownContent>
                            </Dropdown>
                        </div>
                        <div className="Days">
                            {dates.length > 0 ? (
                                <>
                                    {dates.map(
                                        (
                                            date: {
                                                date: Date;
                                                shipping_mode: string;
                                            },
                                            indexDate: number
                                        ) =>
                                            indexDate < 6 ? (
                                                <div
                                                    className={"Day"}
                                                    key={indexDate}
                                                    data-index={indexDate}
                                                >
                                                    <div className="Date">
                                                        <span>
                                                            {moment(date.date)
                                                                .locale("hu")
                                                                .format("dddd")}
                                                        </span>
                                                        <small>
                                                            {moment(
                                                                date.date
                                                            ).format(
                                                                "YYYY.MM.DD"
                                                            )}
                                                        </small>
                                                        {date.shipping_mode !==
                                                            "current_day" && (
                                                            <small className="shipping">
                                                                {getShippingString(
                                                                    date.shipping_mode,
                                                                    date.date
                                                                )}
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div className="Price">
                                                        <NumberFormat
                                                            value={getPrice(
                                                                date.date
                                                            )}
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                                " "
                                                            }
                                                            suffix={" Ft"}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null
                                    )}
                                </>
                            ) : (
                                <>{getFakeDays()}</>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div id="HeaderBottomNavigation">
                <button onClick={swipeRight}>
                    <IonIcon icon={chevronBackOutline} />
                    Előző nap
                </button>
                <button onClick={swipeLeft}>
                    Következő nap
                    <IonIcon icon={chevronForwardOutline} />
                </button>
            </div>
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        cartItems: state.CartReducer.cart.items,
    };
}

export default connect(mapStateToProps)(HeaderBottom);
