import React, { useEffect, useState } from "react";
import moment from "moment";
import { IonIcon, IonSkeletonText } from "@ionic/react";
import { connect } from "react-redux";
import { ADD_DETAILS_FOOD, ADD_TO_CART, REMOVE_FROM_CART } from "../store/cart/ActionTypes";
import { RootState } from "../store/RootReducer";
import { ModelMenuItem, ModelCartItem, ModelMenuIcon } from "../models/FoodModel";
import { openSideMenu } from "../helpers/functions/MenuControllerHelper";
import LoveButton from "./buttons/LoveButton";

import FoodIcon from "./FoodIcon";

import list from "../assets/images/list.svg";
import add from "../assets/images/add.svg";
import remove from "../assets/images/remove.svg";

import "./Food.scss";
import { addAnalytics } from "../services/AnalyticsService";
import { sendBarionPixel } from "../helpers/barion/PixelHelper";
import { categoryDataToCart } from "../helpers/functions/CartHelper";
import { ModelCategory } from "../models/CategoryModel";
import { getImageLink } from "../helpers/functions/OrderPageHelpers";
import { time } from "ionicons/icons";
import classNames from "classnames";

interface StateFromReducer {
  onAddToCart?: any;
  onRemoveFromCart?: any;
  onAddDetailsFood?: any;
}
interface DispatchFromReducer {
  quantity?: number;
}
type Props = StateFromReducer &
  DispatchFromReducer & {
    date: Date;
    menu_item: ModelMenuItem | null;
    category: ModelCategory | null;
    key: string;
    index: number;
    version: number | null;
    lastMinute?: boolean;
  };

const Food: React.FC<Props> = ({ onAddToCart, onRemoveFromCart, onAddDetailsFood, quantity, date, category, menu_item, key, index, version, lastMinute = false }) => {
  const [loved, setLoved] = useState<boolean>(menu_item?.food?.is_favourite ?? false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true);

    return () => {
      setLoaded(false);
    };
  }, []);

  let tempIcons = {
    top: menu_item.icons.filter((icon: any) => icon.position === "top"),
    bottom: menu_item.icons.filter((icon: any) => icon.position === "bottom"),
    inline_float_right: menu_item.icons.filter((icon: any) => icon.position === "inline_float_right"),
  };

  const handleAddDetails = (menu_item: ModelMenuItem, date: Date) => {
    onAddDetailsFood(menu_item, category, date, lastMinute);

    sendBarionPixel("clickProductDetail", {
      contentType: "Product",
      currency: "HUF",
      id: menu_item.id.toString(),
      name: menu_item?.food?.display_name ?? menu_item.weekly_name,
      unit: "pcs.",
      unitPrice: menu_item?.price ?? 0,
      category: menu_item?.category?.name ?? "",
    });

    sendBarionPixel("clickProduct", {
      contentType: "Product",
      currency: "HUF",
      id: menu_item.id.toString(),
      name: menu_item?.food?.display_name ?? menu_item.weekly_name,
      quantity: 1,
      unit: "pcs.",
      unitPrice: menu_item?.price ?? 0,
      category: menu_item?.category?.name ?? "",
    });

    sendBarionPixel("contentView", {
      contentType: "Product",
      currency: "HUF",
      id: menu_item.id.toString(),
      name: menu_item?.food?.display_name ?? menu_item.weekly_name,
      quantity: 1,
      unit: "pcs.",
      unitPrice: menu_item?.price ?? 0,
      category: menu_item?.category?.name ?? "",
      imageUrl: getImageLink(menu_item.id, 1000, 1000),
    });
  };

  return (
    <div className={classNames("Food", { "InCart": quantity > 0, "LastMinute" : menu_item.is_last_minute_orderable })} data-key={key} data-index={index}>
      {menu_item !== null && (
        <>
          {menu_item.is_last_minute_orderable ? (<div className="lastMinuteBar" title="Last Minute"><IonIcon icon={time} /></div>) : null}
          {tempIcons.top !== undefined && tempIcons.top.length > 0 && (
            <div className="TopIcons">
              {tempIcons.top.map((icon: ModelMenuIcon, iconIndex: number) => (
                <FoodIcon icon={icon} key={iconIndex} />
              ))}
            </div>
          )}
          <div className="Title">
            {version ? (
              <a href={"https://ca.cityfood.hu/admin/menu-items/" + menu_item.id + "/edit"} target="_blank">
                {menu_item.food.display_name !== null ? menu_item.food.display_name : menu_item.food.name}
              </a>
            ) : (
              <>{menu_item.food.display_name !== null ? menu_item.food.display_name : menu_item.food.name}</>
            )}

            {tempIcons.inline_float_right !== undefined && tempIcons.inline_float_right.length > 0 && tempIcons.inline_float_right.map((icon: ModelMenuIcon, iconIndex: number) => <FoodIcon icon={icon} key={iconIndex} />)}
          </div>

          {menu_item.comment && <div className="Details">{menu_item.comment ?? ""}</div>}

          <div className="Price">{menu_item.price ?? " - "} Ft</div>
          {lastMinute ? (
            <>
              <div className="Date">{moment(menu_item.date).format("YYYY.MM.DD. - dddd")}</div>
            </>
          ) : null}
          {tempIcons.bottom !== undefined && tempIcons.bottom.length > 0 && (
            <div className="BottomIcons">
              {tempIcons.bottom.map((icon: ModelMenuIcon, iconIndex: number) => (
                <FoodIcon icon={icon} key={iconIndex} />
              ))}
            </div>
          )}
          {loaded ? (
            <div className="Extras">
              <div className="ExtraButtons">
                <button onClick={() => handleAddDetails(menu_item, date)} className="btn btn-primary btn-sm">
                  <img src={list} alt="Részletek" />
                </button>
                <LoveButton menu_item={menu_item} loved={loved} onChange={() => setLoved((prevState) => !prevState)} type="inline" />
              </div>
              <div className="ExtraCart">
                {menu_item.disabled === false ? (
                  <>
                    <button onClick={() => onRemoveFromCart([menu_item], categoryDataToCart(category))} className="btn btn-medium btn-sm">
                      <img src={remove} alt="Minusz" />
                    </button>
                    <span className="Quantity">{quantity ?? 0}</span>
                    <button onClick={() => onAddToCart([menu_item], categoryDataToCart(category))} className="btn btn-medium btn-sm">
                      <img src={add} alt="Plusz" />
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="Extras">
              <div className="ExtraButtons">
                <IonSkeletonText animated style={{ width: "50%", height: "16px" }} />
              </div>
              <div className="ExtraCart">
                <IonSkeletonText animated style={{ width: "50%", height: "16px" }} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
function mapStateToProps(state: RootState, ownProps: Props) {
  const findCartItemIndex = state.CartReducer.cart.items.findIndex((cartItem: ModelCartItem) => moment(cartItem.date).isSame(ownProps.date, "day") && cartItem.menu_item.id === ownProps.menu_item.id);
  return {
    quantity: findCartItemIndex > -1 ? state.CartReducer.cart.items[findCartItemIndex].quantity : 0,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onAddToCart: (
      menu_item: ModelMenuItem[],
      category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
      }
    ) =>
      dispatch({
        type: ADD_TO_CART,
        payload: { menu_item, category },
      }),
    onRemoveFromCart: (
      menu_item: ModelMenuItem[],
      category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
      }
    ) =>
      dispatch({
        type: REMOVE_FROM_CART,
        payload: { menu_item, category },
      }),
    onAddDetailsFood: (menu_item: ModelMenuItem, category: ModelCategory, date: Date, lastMinute: boolean = false) => {
      addAnalytics("food", "details", "App\\Models\\Food", menu_item.food.id);
      dispatch({ type: ADD_DETAILS_FOOD, payload: { menu_item, category, date } });

      if (lastMinute) {
        openSideMenu("foodDetailsMenuRight");
        return;
      }

      if (moment(date).day() > 3 || window.innerWidth <= 1025) {
        openSideMenu("foodDetailsMenuLeft");
      } else {
        openSideMenu("foodDetailsMenuRight");
      }
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Food);
